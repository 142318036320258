// WebSocketContext.js
import React, { createContext, useContext, useEffect } from 'react';
import wsClient from './wsClient';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    useEffect(() => {
        wsClient.handleConnect();
    }, []);

    return (
        <WebSocketContext.Provider value={wsClient}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
