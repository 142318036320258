import React, { useEffect, useRef, useState } from 'react';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import '../styles.scss';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import TextPlugin from 'gsap/TextPlugin';
import { ScrollTrigger } from "gsap/ScrollTrigger";


import logo from '../images/logoyellow.png';
import logowhite from '../images/logowhite.png';
import apps_Resgest from '../images/apps-resgest.png';
import qrcode from '../images/qrcode.png';
import minichic from '../images/minichic.png';
import star_green_03 from '../images/star-green-03.svg';
import star_green from '../images/star-green.svg';
import star_orange from '../images/star-orange.svg';
import star_outline from '../images/star-outline.png';
import courbe from '../images/courbe.svg';

import attention1 from '../images/pb/Customers-Attention.75cd8d32 1.png';
import attention2 from '../images/pb/Customers-Attention.75cd8d32 2.png';
import attention3 from '../images/pb/Customers-Attention.75cd8d32 3.png';
import attention4 from '../images/pb/Customers-Attention.75cd8d32 4.png';
import attention5 from '../images/pb/image-1.png';
import attention6 from '../images/pb/image-2.png';
import attention7 from '../images/pb/image-3.png';
import attention8 from '../images/pb/image.png';

import phone1 from '../images/mockup/phone1.png';
import phone2 from '../images/mockup/phone2.png';
import phone3 from '../images/mockup/phone3.png';
import phone4 from '../images/mockup/phone4.png';

import dashboard from '../images/imageDashboard.png';



gsap.registerPlugin(useGSAP);
gsap.registerPlugin(TextPlugin)

gsap.registerPlugin(ScrollTrigger);

const Landing = () => {

  const restaurantDemo = "./friends-food";

  const navigate = useNavigate();

  const [menuToggle, setMenuToggle] = useState(false);

  const container = useRef();
  const btnMob = useRef();
  const text1 = useRef();
  const text2 = useRef();
  const text3 = useRef();
  const text4 = useRef();
  const header = useRef();

  const etablissements = [
    {
      nom: "Mini Chic House",
      addresse: "Bastos, Yaoundé, Cameroun",
      cover: "https://storage.googleapis.com/uploadregestfiles/privateUploads/ESTABLISHMENT_COVER/couverture%20(1).png",
      description: "Le restaurant Mini Chic se distingue par son atmosphère élégante et intime, offrant à ses convives une expérience gastronomique raffinée. Niché au cœur d'un quartier animé, ce bijou culinaire allie la sophistication d'un décor moderne à la chaleur d'un accueil personnalisé. Les tables finement dressées, ornées de nappes immaculées et de couverts étincelants, invitent à une dégustation exquise. La carte du Mini Chic est un hymne à la gastronomie française, élaborée avec des ingrédients frais et de saison. Chaque plat est une œuvre d'art culinaire, où les saveurs s'harmonisent avec une précision digne des plus grands chefs. Les entrées délicates, les plats principaux savamment composés et les desserts succulents promettent un voyage sensoriel inoubliable",
      revenu: "450 500 XAF",
      category: "Restaurant"
    },
    {
      nom: "Phénicia",
      addresse: "Bonapriso, Douala, Cameroun",
      cover: "https://storage.googleapis.com/uploadregestfiles/privateUploads/ESTABLISHMENT_COVER/WhatsApp%20Image%202024-06-19%20at%2014.21.17.jpeg",
      description: "Spécialité de cuisine Libanaise, européen, camerounais. Au coeur de nos origines",
      revenu: "550 500 XAF",
      category: "Restaurant"
    }
  ];

  const targetValue = 12897500; // La valeur finale que vous souhaitez atteindre
  const duration = 600; // Durée de l'animation en secondes


  const handleMenu = () => {
    setMenuToggle(!menuToggle)
  }


  useEffect(() => {
    const countElement = document.querySelector('.totalAmountMonth');
    // Animation de décompte
    gsap.to(
      { value: 12000000 },
      {
        repeat: -1,
        value: targetValue,
        duration: duration,
        onUpdate: function () {
          countElement.innerText = Math.floor(this.targets()[0].value).toLocaleString('fr-FR') + ' XAF';
        }
      }
    );
  }, []);

  useGSAP(() => {
    //on load animation
    gsap.from(document.querySelector('.linearGrid'), { opacity: 0, duration: 1, delay: 0.5 });
    gsap.from(document.querySelector('.intro'), { backgroundColor: "#FF6B01", duration: 1 });
    gsap.from(text1.current, { opacity: 0, y: -50, duration: 1, delay: 0.5 });
    gsap.from(text2.current, { opacity: 0, y: 50, duration: 1, delay: 0.5 });
    gsap.from(text3.current, { opacity: 0, y: 50, duration: 1, delay: 1 });
    gsap.from(text4.current, { opacity: 0, y: 50, duration: 1, delay: 1.5 });
    gsap.from(header.current, { opacity: 0, y: -50, duration: 1, delay: 1 });

    document.querySelectorAll('.header nav li').forEach((li, index) => {
      gsap.from(li, {
        opacity: 0, scale: 0,
        y: -50,
        duration: 1,
        delay: 2 + index * .3,
      });
    });

    document.querySelectorAll('.Landing .deco:not(.courbe)').forEach((card, index) => {
      gsap.from(card, {
        opacity: 0, scale: 1,
        y: -50,
        rotate: 100,
        duration: 1,
        delay: 1.5 + index * .3,
      });
    });

    document.querySelectorAll('.imageContainer img').forEach((card, index) => {
      gsap.from(card, {
        opacity: 0, scaleX: 0,
        x: 300,
        scale: 0,
        duration: 1,
        delay: 1.1 + index * .3,
      });
    });

    //permanent animation
    document.querySelectorAll('.imageContainer img').forEach((card, index) => {
      gsap.fromTo(card,
        { y: 0, skewX: 3 }, // Point de départ (haut)
        {
          y: 60,
          skewX: -3,
          duration: 1.8,
          repeat: -1, // Répétition infinie
          yoyo: true,
          ease: 'power1.inOut', // Courbe d'animation pour plus de fluidité
          delay: 0 + index * 0.3, // Délai initial basé sur l'index de chaque image
        },
      );
    });


    document.querySelectorAll(".navlink li").forEach((btn, index) => {
      btn.addEventListener("click", () => {
        if (window.innerWidth <= 1080) {
          setMenuToggle(false);
        }
        window.scrollTo(0, document.querySelector("#section" + (index + 1)).offsetTop);
      });
    });
    document.querySelectorAll(".footerLink li").forEach((btn, index) => {
      btn.addEventListener("click", () => {
        window.scrollTo(0, document.querySelector("#section" + (index + 1)).offsetTop);
      });
    });

  }, { scope: document.querySelector(".Landing") });

  useEffect(() => {
    let lastScroll = 0;

    ScrollTrigger.create({
      trigger: document.body,
      start: "top top",
      onUpdate: (self) => {
        const currentScroll = window.scrollY;

        if (currentScroll === 0) {
          gsap.to(header.current, { css: { background: "#ffffff00" }, duration: 0.5, ease: "power2.out" });
        } else {
          gsap.to(header.current, { css: { background: "white" }, duration: 0.5, ease: "power2.out" });
        }

        if (currentScroll > lastScroll) {
          // Scrolling down
          gsap.to(header.current, { y: -100, duration: 0.5, ease: "power2.out" });
        } else {
          // Scrolling up
          gsap.to(header.current, { y: 0, duration: 0.5, ease: "power2.out" });
        }
        lastScroll = currentScroll;
      }
    });





  }, []);



  return (
    <div ref={container} className='Landing'>

      <div id='section1'></div>
      <div className="intro">
        <div className="linearGrid">
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>

        <div ref={header} className="header">
          <div className="lstruct">
            <div className="logo">
              <img src={logo} alt="logo resgest" />
            </div>

            <nav style={{ display: !menuToggle && window.innerWidth <= 1080 ? "none" : "flex" }} className='navlink'>
              <ul>
                <li>Acceuil</li>
                <li>Pourquoi Resgest ?</li>
                <li>Fonctionnalités</li>
                <li>Tarifs</li>
                <li>Contacts</li>
              </ul>
            </nav>


            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1em" }}>
              <NavLink to="./admin/createaccount" className="btncta cta_BTN">
                <div className="text">S’inscrire</div>
              </NavLink>
              <div ref={btnMob} onClick={handleMenu} className="btnMob">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"><path d="M22 8.27V4.23C22 2.64 21.36 2 19.77 2h-4.04c-1.59 0-2.23.64-2.23 2.23v4.04c0 1.59.64 2.23 2.23 2.23h4.04c1.59 0 2.23-.64 2.23-2.23ZM10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98v4.53c0 1.42.64 1.98 2.23 1.98h4.04c1.59.01 2.23-.56 2.23-1.97ZM10.5 19.77v-4.04c0-1.59-.64-2.23-2.23-2.23H4.23c-1.59 0-2.23.64-2.23 2.23v4.04C2 21.36 2.64 22 4.23 22h4.04c1.59 0 2.23-.64 2.23-2.23Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15 15.5h6M15 19.5h6" stroke="#fff" stroke-width="1.5" stroke-linecap="round"></path></svg>
              </div>
            </div>



          </div>

        </div>

        <div className="lstruct">
          <section className='introContentSection'>
            <div className="introContent">
              <section className="textSide">
                {/* <img className='deco star_outline' src={star_outline} alt="" /> */}
                <div ref={text1} className='littleText'>Digitalisez votre service avec Resgest</div>
                <h2 ref={text2}>
                  Commander & payer
                  avec <span className="light">un menu digital</span> par <span className="color">Code Qr</span>
                </h2>
                <p ref={text3}>La solution la plus flexible pour digitaliser votre service. Votre menu digital sur un QR-code : commandez. Recommandez. Payez. Sans téléchargement d'application, ni inscription.</p>

                <div className='containergroupBtn'>
                  <div ref={text4} className="groupBtn">
                    <NavLink to="./admin/createaccount" className="btncta">
                      <div className="text">S’inscrire</div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"><path fill="#fff" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.03 10.53l-3 3c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 010-1.06l1.72-1.72H8.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.19l-1.72-1.72a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3 3c.29.29.29.77 0 1.06z"></path></svg>
                    </NavLink>
                    <NavLink to={restaurantDemo} className="btncta btngreen">
                      <div className="text">Voir la démo</div>
                    </NavLink>

                    <img className='deco star_green' src={star_green} alt="" />
                    <img className='deco star_orange' src={star_orange} alt="" />

                    <img className='deco courbe' src={courbe} alt="" />
                  </div>
                </div>

              </section>

              <section className='imageSide'>
                <div className="imageContainer">
                  <img className='asideImg right' src={minichic} alt="" />
                  <div className="mainImage">
                    <img src={apps_Resgest} alt="" />
                  </div>
                  <img className='asideImg left ' src={qrcode} alt="" />
                </div>
                <img className='deco star_green_03' src={star_green_03} alt="" />
              </section>
            </div>
          </section>
        </div>

        <svg className='courbe_end' viewBox="0 0 1440 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0C0 0 440.117 54.0342 722 54C1002.71 53.9659 1440 0 1440 0V70H0V0Z" fill="#FF6B01" />
        </svg>

      </div>

      <div id='section2'></div>
      <div className="sectionGap">
        <div style={{ display: 'grid', placeItems: "center", width: "100%" }}>
          <div className="lstruct">
            <div style={{ display: 'grid', placeItems: "center", width: "100%" }}>
              <div className="centerContainer">
                <div style={{ background: "white", }} className="tag title">
                  DANS LES 30 DERNIERS JOURS
                </div>
                <h2>20 établissements resgest ont généré :</h2>
                <div style={{ background: "white", color: "green" }} className="tag totalAmountMonth">
                  12,897,500 XAF
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div class="holster">
          <div class="container x mandatory-scroll-snapping" dir="ltr">
            {etablissements.map((establissment, index) => (
              <div key={index} className="establisment">
                <div className="img">
                  <img
                    src={establissment.cover}
                    alt={establissment.nom}
                    className="w-6 shadow-2"
                  />
                </div>
                <div className="detail">
                  <div
                    className="tag"
                    style={{ background: "#E7F4D1", fontWeight: "normal", marginBottom: '10px', color: "green" }}
                  >
                    Restaurant
                  </div>
                  <h4 className="mb-1">{establissment.nom}</h4>
                  <h6 className="mb-1">{establissment.addresse}</h6>
                  <p className="mt-0 mb-3">
                    {establissment.description.length > 300
                      ? establissment.description.slice(0, 300) + '...'
                      : establissment.description}
                  </p>
                  {/* <div className="price">
                    <div className="label">Revenu</div>
                    <div className="value">450,500 XAF</div>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className='sectionGap' style={{ display: 'grid', placeItems: 'center', marginTop: "0px" }}>
        <div className="lstruct ">
          <section className='whiteSection '>
            <h1>Vous <span class="light">ne générez pas</span> de assez de ventes si vous utilisez<span className='tag' style={{ color: "#FF0004", display: "inline", padding: ".3em" }}>un menu traditionnel sur papier</span></h1>

            <div className="cardImg_container">
              <div className="cardImg">
                <div className="img">
                  <img src={attention1} alt="" />
                </div>
                <div className="text">
                  Difficile à capter l’attention des clients
                </div>
              </div>
              <div className="cardImg">
                <div className="img">
                  <img src={attention2} alt="" />
                </div>
                <div className="text">
                  Tableau séparé pour afficher des menu spéciaux et des promotions
                </div>
              </div>
              <div className="cardImg">
                <div className="img">
                  <img src={attention3} alt="" />
                </div>
                <div className="text">
                  Usure constantes et manque d’hygiène
                </div>
              </div>
              <div className="cardImg">
                <div className="img">
                  <img src={attention4} alt="" />
                </div>
                <div className="text">
                  La mise à jour coute cher et prend beaucoup de temps
                </div>
              </div>
              <div className="cardImg">
                <div className="img">
                  <img src={attention5} alt="" />
                </div>
                <div className="text">
                  Coût de remplacement des couvertures usées
                </div>
              </div>
              <div className="cardImg">
                <div className="img">
                  <img src={attention7} alt="" />
                </div>
                <div className="text">
                  Aucune donnée utilisable pour l’analyse, les statistiques et le suivi de la clientelle
                </div>
              </div>
              <div className="cardImg">
                <div className="img">
                  <img src={attention8} alt="" />
                </div>
                <div className="text">
                  Constitue un risque pour l’environnement
                </div>
              </div>
              <div className="cardImg">
                <div className="img">
                  <img src={attention6} alt="" />
                </div>
                <div className="text">
                  Pas rentable
                </div>
              </div>

            </div>



          </section>

          <section className='sectionGap' >
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <h1 className='outlineText'>
                Découvrez <span className='imgLogo'><img src={logo} style={{ display: "inline-block" }} alt="" /></span> <br /> L’avenir de la restauration désormais à portée de main
              </h1>
            </div>


            <div className="flexcontainer usageflexcontainer">
              <div className="itemphone">
                <div className="num">1.</div>
                <div className="img">
                  <img src={phone1} alt="" />
                </div>
                <div className="description">
                  Scannez le code Qr, avec l’appareil photo ou une autre application
                </div>
              </div>
              <div className="itemphone">
                <div className="num">2.</div>
                <div className="img">
                  <img src={phone2} alt="" />
                </div>
                <div className="description">
                  Naviguez entre les différentes catégories de menus disponibles
                </div>
              </div>
              <div className="itemphone">
                <div className="num">3.</div>
                <div className="img">
                  <img src={phone3} alt="" />
                </div>
                <div className="description">
                  Sélectionnez votre plat et ajoutez le à votre panier
                </div>
              </div>
              <div className="itemphone">
                <div className="num">4.</div>
                <div className="img">
                  <img src={phone4} alt="" />
                </div>
                <div className="description">
                  Entrez vos informations et passez votre  commande
                </div>
              </div>
            </div>

          </section>

          <div id='section3'></div>
          <section className='whiteSection sectionGap'>
            <h1 className='outlineText outlineTextCTA'>
              Les fonctionnalités & les Caractéristiques de <br /> <span className='imgLogo' style={{ background: "#ff6b01" }}><img src={logowhite} style={{ display: "inline-block", }} alt="" /></span>
            </h1>


            <div className="cardText_container">
              <div className="cardText fond1">
                <div className="num">01</div>
                <div className="title">Système de commande en ligne et sur place via code QR</div>
                <div className="description">Resgest permet aux clients de passer des commandes à la fois en ligne et sur place en utilisant un simple scan de code QR. Les clients peuvent accéder instantanément au menu, choisir leurs plats, et finaliser leurs commandes sans attendre le personnel.</div>
              </div>
              <div className="cardText fond2">
                <div className="num">02</div>
                <div className="title">Ajout illimité de repas et de catégories</div>
                <div className="description">Resgest permet aux établissements d'ajouter un nombre illimité de repas et de catégories au menu. Cela offre une grande flexibilité pour adapter le menu selon les saisons, les promotions, et les préférences des clients.</div>
              </div>
              <div className="cardText fond3">
                <div className="num">03</div>
                <div className="title">Activation et désactivation de repas ou de catégories</div>
                <div className="description">Cette fonctionnalité permet d'activer ou de désactiver rapidement des plats ou des catégories entières. Par exemple, si un plat n'est plus disponible ou si une catégorie est temporairement retirée, cela évite aux clients de selectionner les repas non disponibles.</div>
              </div>
              <div className="cardText fond4">
                <div className="num">04</div>
                <div className="title">Création automatique d’un porte-feuille client</div>
                <div className="description">Après une commande, les informations du client sont automatiquement stocké dans votre porte feuille client, ce qui vous permettra de fidéliser votre clientèle et de les relancer . Cela encourage les clients à revenir et facilite les commandes répétées.</div>
              </div>
              <div className="cardText fond5">
                <div className="num">05</div>
                <div className="title">Système Suivi automatique de la clientèle</div>
                <div className="description">Resgest inclut des outils de suivi de la clientèle, vous permettant de recueillir des données précieuses sur les préférences et les habitudes d'achat des clients. Ces informations peuvent être utilisées pour personnaliser les offres et améliorer le service à la clientèle.</div>
              </div>
              <div className="cardText fond6">
                <div className="num">06</div>
                <div className="title">Historiques de ventes et de commandes</div>
                <div className="description">Les gestionnaires peuvent accéder à des rapports détaillés sur les ventes et les commandes passées. Cela permet d'analyser les performances, de repérer les tendances, et de prendre des décisions informées pour optimiser le menu et les opérations.</div>
              </div>
            </div>
          </section>


          <div id='section4'></div>
          <section className='sectionGap' >
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <h1 className='outlineText'>
                Commencez <br /> aujourd'hui avec  <br /><span className='imgLogo'><img src={logo} style={{ display: "inline-block" }} alt="" /></span>
              </h1>
            </div>


            <div className="flexcontainer cardPlanflexcontainer" >
              <div className="cardPlan">
                <div className="title">Plan Mensuel</div>
                <div className="price"> XAF <span>50,000</span> /MOIS </div>
                <NavLink to={"./admin/createaccount"} className="launchBTN">Démarrer</NavLink>
                <ul>
                  <li>01 compte restaurant ou hôtel</li>
                  <li>Ajout illimité de catégories</li>
                  <li>Ajout illimité d'articles</li>
                  <li>Commandes en temps réels</li>
                  <li>Statistiques & historiques des ventes</li>
                  <li>Accèss administrateurs illimités</li>
                  <li>Génération et impression des tickets de commande</li>
                  <li>Gestion automatique de la comptabilité des ventes</li>
                  <li>Construction automatique d'un portefeuille client</li>
                  <li>Géneration illimité de code qr</li>
                  <li>Formation du personnel gratuite</li>
                </ul>
              </div>
              <div className="cardPlan">
                <div className="title">Plan Annuel</div>
                <div className="price"> XAF <span>500,000</span> /AN </div>
                <NavLink to={"./admin/createaccount"} className="launchBTN">Démarrer</NavLink>
                <ul>
                  <li>01 compte restaurant ou hôtel</li>
                  <li>Ajout illimité de catégories</li>
                  <li>Ajout illimité d'articles</li>
                  <li>Commandes en temps réels</li>
                  <li>Statistiques & historiques des ventes</li>
                  <li>Accèss administrateurs illimités</li>
                  <li>Génération et impression des tickets de commande</li>
                  <li>Gestion automatique de la comptabilité des ventes</li>
                  <li>Construction automatique d'un portefeuille client</li>
                  <li>Géneration illimité de code qr</li>
                  <li>Formation du personnel gratuite</li>
                </ul>
              </div>
              <div className="cardPlan">
                <div className="title">Plan à vie</div>
                <div className="price"> XAF <span>4,500,000</span> </div>
                <NavLink to={"./admin/createaccount"} className="launchBTN">Démarrer</NavLink>
                <ul>
                  <li><strong>250,000</strong> XAF/ ANS <br /> Pour l’hébergement et la maintenance</li>
                  <li>10 comptes restaurant ou hôtel</li>
                  <li>Ajout illimité de catégories</li>
                  <li>Ajout illimité d'articles</li>
                  <li>Commandes en temps réels</li>
                  <li>Statistiques & historiques des ventes</li>
                  <li>Accèss administrateurs illimités</li>
                  <li>Génération et impression des tickets de commande</li>
                  <li>Gestion automatique de la comptabilité des ventes</li>
                  <li>Construction automatique d'un portefeuille client</li>
                  <li>Géneration illimité de code qr</li>
                  <li>Formation du personnel gratuite</li>
                </ul>
              </div>
            </div>

          </section>
          <section className='grid' style={{ marginBottom: "0px" }}>
            <div style={{ display: 'grid', placeItems: 'center' }}>
              <h1 className='outlineText'>
                Simplifiez vos commandes et passez au Menu Digital Innovant avec  <br /><span className='imgLogo'><img src={logo} style={{ display: "inline-block" }} alt="" /></span>
              </h1>
              <br />
              <br />
              <br />
              <NavLink to="./admin/createaccount" className="btncta btnctaWhite">
                <div className="text">Commencez maintenant</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"><path fill="#fff" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.03 10.53l-3 3c-.15.15-.34.22-.53.22s-.38-.07-.53-.22a.754.754 0 010-1.06l1.72-1.72H8.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.19l-1.72-1.72a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3 3c.29.29.29.77 0 1.06z"></path></svg>
              </NavLink>


              <div className="dashboardimg">
                <img src={dashboard} alt="" />
              </div>


            </div>



          </section>
        </div>
      </div>

      <svg className='courbe_end' viewBox="0 0 1440 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0C0 0 440.117 54.0342 722 54C1002.71 53.9659 1440 0 1440 0V70H0V0Z" fill="#fff" />
      </svg>

      <footer style={{ display: "grid", placeItems: 'center' }} className='footerLanding'>
        <div id='section5'></div>
        <div className="lstruct">
          <div className="flexcontener">
            <div className="about">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <p>
                La solution la plus flexible pour digitaliser votre service. Votre menu digital sur un QR-code : commandez. Recommandez. Payez. Sans téléchargement d'application, ni inscription.
              </p>
            </div>

            <div className="links footerLink">
              <ul>
                <li>Acceuil</li>
                <li>Pourquoi Resgest ?</li>
                <li>Fontionnalités</li>
                <li>Tarifs</li>
              </ul>
            </div>

            <div className="contacts">
              <div className="title">
                Contactez-nous
              </div>
              <div><NavLink to={"mailto:florian.tchomga@gmail.com"}>florian.tchomga@gmail.com</NavLink></div>
              <div>
                <NavLink to={"tel:(+237) 697132706"}>(+237) 697132706</NavLink>
              </div>

            </div>


          </div>

          <div className="copyright">
            <div className="ico logo">
              <svg viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.1922 12.2656V20.5249C2.1922 28.7841 4.96612 32.0952 11.8855 32.0952H20.1919C27.1113 32.0952 29.8852 28.7841 29.8852 20.5249V12.2656" fill="black" />
                <path d="M2.1922 12.2656V20.5249C2.1922 28.7841 4.96612 32.0952 11.8855 32.0952H20.1919C27.1113 32.0952 29.8852 28.7841 29.8852 20.5249V12.2656" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.0393 16.5393C18.883 16.5393 20.9808 14.224 20.7011 11.3803L19.6755 1H12.4186L11.3775 11.3803C11.0978 14.224 13.1956 16.5393 16.0393 16.5393Z" fill="black" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M25.8439 16.5393C28.9828 16.5393 31.2826 13.9909 30.9719 10.8675L30.5368 6.59416C29.9773 2.55393 28.4234 1 24.3521 1H19.6126L20.7004 11.8931C20.9645 14.4571 23.2799 16.5393 25.8439 16.5393Z" fill="black" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.15611 16.5393C8.7201 16.5393 11.0355 14.4571 11.2841 11.8931L11.626 8.45888L12.3718 1H7.63235C3.56104 1 2.00711 2.55393 1.4477 6.59416L1.02813 10.8675C0.717348 13.9909 3.01717 16.5393 6.15611 16.5393Z" fill="black" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.0385 24.3086C13.4435 24.3086 12.1537 25.5984 12.1537 28.1934V32.0783H19.9233V28.1934C19.9233 25.5984 18.6336 24.3086 16.0385 24.3086Z" fill="black" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>

            <div className="text">© 2024 Resgest - Tous droits réservés. Conçu avec passion</div>


            <div className="socials">
              <NavLink className={'ico'} >
                <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28.7106 9.48201C28.7309 9.76626 28.7309 10.0506 28.7309 10.3348C28.7309 19.0048 22.132 28.9947 10.0711 28.9947C6.35531 28.9947 2.90356 27.9185 0 26.0506C0.527937 26.1114 1.0355 26.1318 1.58375 26.1318C4.64969 26.1318 7.47206 25.0963 9.72587 23.3298C6.84262 23.2688 4.42637 21.3805 3.59387 18.7815C4 18.8424 4.40606 18.883 4.8325 18.883C5.42131 18.883 6.01019 18.8018 6.55837 18.6597C3.55331 18.0505 1.29944 15.4109 1.29944 12.2231V12.1419C2.1725 12.6293 3.18781 12.9338 4.26388 12.9744C2.49738 11.7967 1.34006 9.78657 1.34006 7.51245C1.34006 6.2942 1.66488 5.17745 2.23344 4.20282C5.46187 8.18251 10.3147 10.7814 15.7563 11.0658C15.6548 10.5784 15.5938 10.0709 15.5938 9.56326C15.5938 5.94901 18.5177 3.00488 22.1522 3.00488C24.0405 3.00488 25.7461 3.79676 26.9441 5.07595C28.4263 4.7917 29.8476 4.24345 31.1065 3.4922C30.6191 5.01507 29.5836 6.29426 28.2232 7.10638C29.5431 6.96432 30.8222 6.59876 31.9999 6.0912C31.1066 7.39063 29.9898 8.54795 28.7106 9.48201Z" fill="white" />
                </svg>
              </NavLink>
              <NavLink to={"https://web.facebook.com/profile.php?id=61557131738537"} target='_blank' className={'ico'}>
                <svg viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.8293 15.1875L15.5848 10.3011H10.8614V7.13022C10.8614 5.7934 11.5212 4.49033 13.6367 4.49033H15.784V0.330117C15.784 0.330117 13.8353 0 11.9723 0C8.08244 0 5.53988 2.34035 5.53988 6.57703V10.3011H1.21603V15.1875H5.53988V27H10.8614V15.1875H14.8293Z" fill="white" />
                </svg>

              </NavLink>
              <NavLink to={"https://www.linkedin.com/company/105210814"} target='_blank' className={'ico'}>
                <svg viewBox="0 0 27 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.04366 27.1254H0.445982V9.0158H6.04366V27.1254ZM3.24181 6.54549C1.45185 6.54549 0 5.05604 0 3.25779C1.28117e-08 2.39403 0.341547 1.56565 0.949504 0.954876C1.55746 0.344104 2.38203 0.000976562 3.24181 0.000976562C4.10159 0.000976562 4.92616 0.344104 5.53411 0.954876C6.14207 1.56565 6.48362 2.39403 6.48362 3.25779C6.48362 5.05604 5.03116 6.54549 3.24181 6.54549ZM26.994 27.1254H21.4083V18.3097C21.4083 16.2088 21.3662 13.5144 18.498 13.5144C15.5877 13.5144 15.1417 15.797 15.1417 18.1584V27.1254H9.55005V9.0158H14.9187V11.4861H14.9971C15.7444 10.0633 17.5699 8.5617 20.2934 8.5617C25.9586 8.5617 27 12.3096 27 17.1775V27.1254H26.994Z" fill="white" />
                </svg>

              </NavLink>
            </div>


          </div>

        </div>
      </footer>



    </div>
  );
};

export default Landing;