import React, { useState, useEffect, useRef } from "react";
import { getOrders } from "../API/api";
import { getUserData } from "../../utils";
import { setOrderStatus } from "../API/api";
import BagOrderAdmin from "../../menuApp/components/BagOrderAdmin";
import DisplayOrders from "./DisplayOrders";
import DisplayOrdersSale from "./DisplayOrderSale";
import { useWebSocket } from "../../webSocketContext"; // Assurez-vous que le chemin est correct
import AudioPlayer from "./AudioPlayer";
import Swal from "sweetalert2";

const LiveOrder = (props) => {
  const wsClient = useWebSocket();
  const param = props.title ? props.title : "Live Order Management";

  const [isPanierDisplay, setIsPanierDisplay] = useState(false);
  const [orders, setOrders] = useState(props.orders);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [amountSelectedOrder, setAmountSelectedOrder] = useState(0);

  // const [newOrder, setNewOrder] = useState(String(Date.now()));

  const [notify, setNotify] = useState(false);

  const establishmentID = getUserData().ESTABLISSEMENT.ESTABLISHMENT_ID;

  const closePanier = () => {
    setIsPanierDisplay(false);
  };

  const handleViewOrder = (order) => {
    console.log("current secteted order : ", order);

    let initialAmount = 0;
    const cart = JSON.parse(order.ORDER_DATA);
    if (cart) {
      cart.map((item) => (initialAmount = initialAmount + Number(item.price)));
    }

    setAmountSelectedOrder(initialAmount);

    setSelectedOrder(order);
    setIsPanierDisplay(true);
  };

  const fn_setOrderStatus = async (order, setStatus) => {
    try {
      const sendFormData = new FormData();
      sendFormData.append("ORDER", JSON.stringify(order));
      sendFormData.append("ORDER_STATUS", setStatus);

      setOrderStatus(sendFormData).then(async (res) => {
        if (res && res.reponse === 1) {
          console.log("setStatus", res);
          if (props.refreschDashboard) props.refreschDashboard();
        } else {
          console.log("setStatus", res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidateOrder = async (order) => {
    await fn_setOrderStatus(order, 2);
    if (props.fetchStats_temp) {
      await props.fetchStats_temp();
    }
  };

  const handleCancelOrder = async (order) => {
    await fn_setOrderStatus(order, 3);
  };

  // useEffect(() => {
  //   const handleMessage = async (reponse) => {
  //     // console.log("reponse LIVE ORDER ------------------------->",reponse);
  //     // console.log("reponse LIVE ORDER ------------------------->",reponse);
  //     // console.log("reponse LIVE ORDER ------------------------->",reponse);

  //     if (
  //       reponse.data.ESTABLISHMENT_ID === establishmentID &&
  //       reponse.event === "createOrder____________Backend" &&
  //       window.location.href.includes("admin/app")
  //     ) {
  //       setNotify(true);
  //       // setNewOrder(String(Date.now()));

  //       await Swal.fire({
  //         icon: "success",
  //         title: `Nouvelle <br> Commande : ${reponse.data.ORDER_TYPE}  !`,
  //         background: "#fff",
  //         text: "Allez à l'onglet commande pour afficher la commande",
  //         backdrop: `
  //           rgba(0,0,123,0.4)
  //           url('https://storage.googleapis.com/uploadregestfiles/assets/nyan-cat.gif')
  //           left top
  //           no-repeat
  //         `,
  //       });
  //     } else if (
  //       reponse.data.ESTABLISHMENT_ID === establishmentID &&
  //       reponse.event === "updateOrder____________Backend" &&
  //       window.location.href.includes("admin/app")
  //     ) {
  //       // setNewOrder(String(Date.now()));
  //     }
  //   };

  //   if (wsClient && wsClient.socket) {
  //     wsClient.socket.on("message", handleMessage);
  //     // console.log("IN WEB SOCKET LIVE ORDER..........................")
  //     // console.log("IN WEB SOCKET LIVE ORDER..........................")
  //     // console.log("IN WEB SOCKET LIVE ORDER..........................")
  //   }

  //   return () => {
  //     if (wsClient && wsClient.socket) {
  //       wsClient.socket.off("message", handleMessage);
  //     }
  //   };
  // }, [wsClient, notify, establishmentID]);

  const handleMessage = async (reponse) => {
    // console.log("reponse LIVE ORDER ------------------------->",reponse);
    // console.log("reponse LIVE ORDER ------------------------->",reponse);
    // console.log("reponse LIVE ORDER ------------------------->",reponse);

    if (
      reponse.data.ESTABLISHMENT_ID === establishmentID &&
      reponse.event === "createOrder____________Backend" &&
      window.location.href.includes("admin/app")
    ) {
      setNotify(true);
      // setNewOrder(String(Date.now()));

      // await Swal.fire({
      //   icon: "success",
      //   title: `Nouvelle <br> Commande : ${reponse.data.ORDER_TYPE}  !`,
      //   background: "#fff",
      //   text: "Allez à l'onglet commande pour afficher la commande",
      //   backdrop: `
      //     rgba(0,0,123,0.4)
      //     url('https://storage.googleapis.com/uploadregestfiles/assets/nyan-cat.gif')
      //     left top
      //     no-repeat
      //   `,
      // });
    } else if (
      reponse.data.ESTABLISHMENT_ID === establishmentID &&
      reponse.event === "updateOrder____________Backend" &&
      window.location.href.includes("admin/app")
    ) {
      // setNewOrder(String(Date.now()));
    }
  };

  if (wsClient && wsClient.socket) {
    wsClient.socket.on("message", handleMessage);
    // console.log("IN WEB SOCKET LIVE ORDER..........................")
    // console.log("IN WEB SOCKET LIVE ORDER..........................")
    // console.log("IN WEB SOCKET LIVE ORDER..........................")
  }

  return (
    <div className="section section4">
      <div className="orderTab">
        <div className="head_statItem">
          <span className="title"> {param}</span>
          <select name="time" id="">
            <option value="This month">This month</option>
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
          </select>
        </div>

        <div className="table_container">
          <div className="table_container__innerContainer">
            <table className="table">
              <tbody>
                <tr className="thead">
                  <td>Actions</td>
                  <td>Amount</td>
                  <td>Order Status</td>
                  <td>Service</td>
                  <td ordertype="Client Table">Client Table</td>
                  <td>Client Name</td>
                  <td>Client Phone</td>
                  <td>Date</td>
                </tr>

                {
                  <DisplayOrders
                    handleViewOrder={handleViewOrder}
                  ></DisplayOrders>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div className="actionbtn" style={{ display: "none" }}>
          {" "}
          <div className="text">View more</div>{" "}
          <svg
            className="frame"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 12H4"
              stroke="#00B031"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 17C15 17 20 13.3176 20 12C20 10.6824 15 7 15 7"
              stroke="#00B031"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      {/* {notify && (
        <AudioPlayer
          id="background-audio"
          src="https://storage.googleapis.com/uploadregestfiles/assets/Doorbell%20chime%20sound%20effect%20%E2%80%A2%20Uppbeat.mp3"
          loop={true}
        ></AudioPlayer>
      )} */}

      {isPanierDisplay && (
        <>
          <BagOrderAdmin
            handleValidateOrder={handleValidateOrder}
            handleCancelOrder={handleCancelOrder}
            currentOrder={selectedOrder}
            amountSelectedOrder={amountSelectedOrder}
            handleClick={closePanier}
          ></BagOrderAdmin>
        </>
      )}
    </div>
  );
};

export default LiveOrder;
