import { useEffect } from 'react';
import { endpoints } from './Admin/API/EndPoints';




// Fonction pour sauvegarder les données dans le stockage local
function saveData(token) {
    const data = { notification_subscription: token };
    localStorage.setItem("subscription_userData", JSON.stringify(data));
    console.log("Data saved to local storage!");
}

// Fonction pour envoyer les informations de souscription au serveur
async function sendSubscriptionInfo(subscription) {
    try {
        await fetch(endpoints.public.subscribeNotification, {
            method: 'POST',
            body: subscription,
            headers: {}
        });
        console.log('Subscription info sent to the server:', subscription);
    } catch (error) {
        console.error('Failed to send subscription info to the server:', error);
    }
}

const PushNotificationManager = () => {
    useEffect(() => {
        let intervalId;

        function subscribeNotificationPermission() {
            navigator.serviceWorker.ready.then((sw) => {
                sw.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
                }).then(async (subscription) => {

                    let subscriptionJSON = JSON.stringify(subscription);
                    saveData(subscriptionJSON);

                    const formData = new FormData();
                    formData.append("subscription", subscriptionJSON);

                    await sendSubscriptionInfo(formData);

                    // Une fois la permission accordée, annuler l'intervalle
                    clearInterval(intervalId);
                }).catch((error) => {
                    console.error('Subscription failed:', error);
                });
            });
        }

        function requestNotificationPermission() {
            if ('Notification' in window) {
                if (Notification.permission === 'default') {
                    Notification.requestPermission().then(function (permission) {
                        if (permission === 'granted') {
                            subscribeNotificationPermission();
                            console.log('Permission Granted You can now receive notifications.');
                        } else if (permission === 'denied') {
                            console.warn('Notification permission denied');
                        } else {
                            console.warn('Notification permission dismissed');
                        }
                    });
                } else if (Notification.permission === 'granted') {
                    console.log('Permission Granted You can now receive notifications.');
                    subscribeNotificationPermission();
                } else if (Notification.permission === 'denied') {
                    console.warn('Notification permission denied');
                }
            } else {
                console.warn('Notifications not supported in this browser');
            }
        }

        if ('serviceWorker' in navigator && 'PushManager' in window) {

            const isClient = localStorage.getItem("subscription_userData") ? true : false;

            if (!isClient) {
                console.log('User is not a web push Client');
                console.log('adding User to be a web push Client........');
                requestNotificationPermission();
                // Démarrer l'intervalle pour redemander la permission toutes les 10 secondes
                intervalId = setInterval(requestNotificationPermission, 10000);
            } else {
                console.log('User is already a web push Client')
            }

        } else {
            console.warn('Push messaging is not supported');
        }

        return () => clearInterval(intervalId);
    }, []);

    return null;
};

export default PushNotificationManager;

