import React, { useState, useRef, useEffect } from "react";
import PanierItemAdmin from "./PanierItemAdmin";
import { formatNumberWith } from "../../utils";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { getUserData } from "../../utils";
import html2canvas from "html2canvas";
import { useQrious } from "react-qrious";

import ReactToPrint from "react-to-print";

// const OrderTicket = ({ order }) => {
//   const ticketRef = useRef();

//   const establisment = getUserData().currentEstablishment;

//   const [dataUrl, _qrious] = useQrious({
//     value: window.location.origin + '/' + establisment.ESTABLISHMENT_LINK,
//     size: 1000,
//     foreground: "#000",
//     level: "H",
//     padding: 25,
//   });

//   const handlePrint = () => {
//     const printContents = ticketRef.current.innerHTML;
//     const originalContents = document.body.innerHTML;

//     document.body.innerHTML = printContents;
//     document.body.style = 'display:block';
//     window.print();
//     document.body.style = 'display:flex';
//     document.body.innerHTML = originalContents;
//     // window.location.reload(); // Pour réinitialiser l'affichage après impression
//   };

//   return (
//     <div className="order-ticket">
//       <div ref={ticketRef} className="ticket">
//         <h2 style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} className="center-text">
//           <div style={{ width: '100px', }}>
//             <img style={{ width: '100%' }} src={establisment.ESTABLISHMENT_LOGO} alt={establisment.ESTABLISHMENT_NAME} />
//           </div>
//         </h2>
//         <h2 className="center-text">{establisment.ESTABLISHMENT_NAME}</h2>
//         <p className="center-text">{establisment.ESTABLISHMENT_LOCATION}</p>
//         <p className="center-text">Tel: {establisment.ESTABLISHMENT_PHONE}</p>
//         <hr />
//         <h3 className="center-text">REÇU DE PAIEMENT</h3>
//         <hr />
//         <div style={{ textAlign: 'left', display: "flex", flexDirection: "column", gap: '5px' }}>
//           <div><strong>Client:</strong> {order.CLIENT_NAME}</div>
//           <div><strong>Téléphone:</strong> {order.CLIENT_TEL}</div>
//           <div><strong>Date:</strong> {new Date(parseInt(order.CREATEAT)).toLocaleString()}</div>
//           <div><strong>ID:</strong> {order.ORDER_ID}</div>
//           <div><strong>Type de commande:</strong> {order.ORDER_TYPE}</div>
//         </div>
//         <table>
//           <thead>
//             <tr>
//               <th>Description</th>
//               <th>Qte</th>
//               <th>Prix</th>
//             </tr>
//           </thead>
//           <tbody>
//             {JSON.parse(order.ORDER_DATA).map((item) => (
//               <tr key={item.id}>
//                 <td> {item.nom.length > 15 ? item.nom.substring(0, 15) + '...' : item.nom}</td>
//                 <td>{item.qte}</td>
//                 <td>{item.price.toFixed(2)}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <hr />
//         <div className="totals">
//           <p><strong>Total:</strong> {order.ORDER_AMOUNT} FCFA</p>
//           <p><strong>Espèces:</strong> 100000 FCFA</p>
//           <p><strong>Monnaie rendue:</strong> {(100000 - order.ORDER_AMOUNT).toFixed(2)} FCFA</p>
//         </div>
//         <hr />
//         <p className="center-text">Merci pour votre achat !</p>
//         <div className="barcode">
//           {/* Vous pouvez ajouter une vraie image de code-barres ici */}
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <img style={{ width: '100px' }} id="QRCODE" src={dataUrl} alt="imgQR" />
//           </div>
//           <div>Notre menu</div>
//         </div>
//         <button onClick={handlePrint}>Imprimer le ticket</button>
//       </div>
//     </div>
//   );


//   // return (
//   //   <div className="order-ticket" style={{ flexDirection: 'column' }}>
//   //     <div ref={ticketRef} className="ticket">
//   //       <h2>Ticket de Commande</h2>
//   //       <p><strong>Client:</strong> {order.CLIENT_NAME}</p>
//   //       <p><strong>Téléphone:</strong> {order.CLIENT_TEL}</p>
//   //       <p><strong>Date de commande:</strong> {new Date(parseInt(order.CREATEAT)).toLocaleString()}</p>
//   //       <p><strong>ID de la commande:</strong> {order.ORDER_ID}</p>
//   //       <p><strong>Type de commande:</strong> {order.ORDER_TYPE}</p>
//   //       <p><strong>Montant total:</strong> {order.ORDER_AMOUNT} FCFA</p>

//   //       <h3>Détails des articles</h3>
//   //       {JSON.parse(order.ORDER_DATA).map((item) => (
//   //         <div key={item.id} className="item">
//   //           <p><strong>Nom de l'article:</strong> {item.nom}</p>
//   //           <p><strong>Quantité:</strong> {item.qte}</p>
//   //           <p><strong>Prix:</strong> {item.price} FCFA</p>
//   //           <p><strong>Temps estimé:</strong> {item.time} minutes</p>
//   //           <img
//   //             src={item.ARTICLE_IMG_1}
//   //             alt={item.nom}
//   //             style={{ width: "100px", height: "100px" }}
//   //           />
//   //         </div>
//   //       ))}
//   //     </div>
//   //     <button onClick={handlePrint}>Imprimer le ticket</button>
//   //   </div>
//   // );
// };

const OrderTicket = ({ order }) => {
  const ticketRef = useRef();

  const establisment = getUserData().ESTABLISSEMENT;
  const [logoBase64, setLogoBase64] = useState('');


  const convertImageToBase64 = (url, callback) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Important pour éviter les problèmes de CORS
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
    };

    img.onerror = (err) => {
      console.error('Erreur lors du chargement de l\'image :', err);
      callback(null);
    };
  };



  const [dataUrl, _qrious] = useQrious({
    value: window.location.origin + '/' + establisment.ESTABLISHMENT_LINK,
    size: 1000,
    foreground: "#000",
    level: "H",
    padding: 25,
  });

  const handlePrint = () => {
    html2canvas(ticketRef.current, { scale: 2.5 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const printWindow = window.open('', '_blank');
      printWindow.document.write('<html><head><title>Impression Ticket</title></head><body style="margin: 0 auto;display: flex;justify-content: center;align-items: flex-start;">');
      printWindow.document.write(`<img src="${imgData}"  style="width: auto;" />`);
      printWindow.document.write('</body></html>');
      printWindow.document.close();

      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 200);

    });
    html2canvas(ticketRef.current, { scale: 2.5 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const printWindow = window.open('', '_blank');
      printWindow.document.write('<html><head><title>Impression Ticket</title></head><body style="margin: 0 auto;display: flex;justify-content: center;align-items: flex-start;">');
      printWindow.document.write(`<img src="${imgData}"  style="width: auto;" />`);
      printWindow.document.write('</body></html>');
      printWindow.document.close();

      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      }, 200);

    });
  };


  useEffect(() => {
    if (establisment.ESTABLISHMENT_LOGO) {
      // Conversion de l'image en Base64
      convertImageToBase64(establisment.ESTABLISHMENT_LOGO, (base64) => {
        if (base64) {
          setLogoBase64(base64); // Stocke l'image en base64 dans l'état
        }
      });
    }
  }, [establisment.ESTABLISHMENT_LOGO]);

  return (
    <>
      <ReactToPrint
        trigger={() => <button className="printTicketBTN">Imprimer le ticket</button>}
        content={() => ticketRef.current}
      />

      <div ref={ticketRef} className="order-ticket">
        <div className="ticket">
          <br />
          {/* {logoBase64 && <h2 style={{ display: "flex", justifyContent: "center", alignItems: 'center' }} className="center-text">
            <div style={{ width: '100px', }}>
              <img style={{ width: '100%' }} src={logoBase64} alt={establisment.ESTABLISHMENT_NAME} />
            </div>
          </h2>} */}
          <h2 className="center-text">{establisment.ESTABLISHMENT_NAME}</h2>
          <p className="center-text">{establisment.ESTABLISHMENT_LOCATION}</p>
          <p className="center-text">Tel: {establisment.ESTABLISHMENT_PHONE}</p>
          <br />
          <hr />
          <h3 className="center-text">REÇU DE PAIEMENT</h3>
          <hr />
          <br />
          <div style={{ textAlign: 'left', display: "flex", flexDirection: "column", gap: '5px' }}>
            <div><strong>Date:</strong> {new Date(parseInt(order.CREATEAT)).toLocaleString()}</div>
            <div><strong>Client:</strong> {order.CLIENT_NAME}</div>
            <div><strong>Téléphone:</strong> {order.CLIENT_TEL}</div>
            <div><strong>ID:</strong> {order.ORDER_ID}</div>
            <div><strong>Place:</strong> {order.ORDER_TYPE}</div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Description</th>
                <th>Qte</th>
                <th>Prix</th>
              </tr>
            </thead>
            <tbody>
              {JSON.parse(order.ORDER_DATA).map((item) => (
                <tr key={item.id}>
                  <td> {item.nom.length > 15 ? item.nom.substring(0, 15) + '...' : item.nom}</td>
                  <td>{item.qte}</td>
                  <td>{item.price.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
          <div className="totals">
            <p><strong>Total:</strong> {order.ORDER_AMOUNT} FCFA</p>
            {/* <p><strong>Espèces:</strong> 100000 FCFA</p>
            <p><strong>Monnaie rendue:</strong> {(100000 - order.ORDER_AMOUNT).toFixed(2)} FCFA</p> */}
          </div>
          <hr />
          <p className="center-text">Merci pour votre achat !</p>
          <div className="barcode">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img style={{ width: '100px' }} id="QRCODE" src={dataUrl} alt="imgQR" />
            </div>
            <div>Notre menu</div>
          </div>

        </div>

      </div>

    </>
  );
};


const BagOrderAdmin = (props) => {
  const readCard = props.currentOrder
    ? JSON.parse(props.currentOrder.ORDER_DATA)
    : [];

  console.log("currentOrder selected", readCard);

  const [cart, setCart] = useState([...readCard].reverse());

  const tempcart = [...readCard].reverse();

  // Fonction pour supprimer un élément du panier
  const removeFromCart = (itemId) => {
    if (cart) {
      const updatedCart = cart.filter((item) => item.id !== itemId);
      localStorage.setItem("cart", JSON.stringify([...updatedCart]));
      setCart([...updatedCart]);
    }
  };

  const get_Basket_Amount = () => {
    let amountBasket = 0;
    if (cart && cart.length !== 0) {
      cart.map((item) => (amountBasket = amountBasket + Number(item.price)));
      return amountBasket;
    } else {
      return 0;
    }
  };

  const handleClickActions = () => {
    get_Basket_Amount();
    props.handleClick();
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Are you sure <br> to cancel ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes !",
    }).then((result) => {
      if (result.isConfirmed) {
        props.handleCancelOrder(props.currentOrder);
        props.handleClick();
      }
    });
  };

  const handleValidate = async () => {
    Swal.fire({
      title: "Are you sure <br> to confirm ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "yes !",
    }).then(async (result) => {
      if (result.isConfirmed) {
        props.handleValidateOrder(props.currentOrder);
        props.handleClick();
      }
    });
  };

  return (
    <div className="panierBox panierBoxAdmin">
      <div className="panierBox__struct">
        <h2>{props.currentOrder.CLIENT_NAME}</h2>
        <h4>{props.currentOrder.ORDER_TYPE}</h4>
        <h4>
          {Number(props.currentOrder.SERVICE_TYPE) === 1
            ? "Manger sur place"
            : "Emporter"}
        </h4>
        <h4>
          <NavLink className="link" to={`tel:${props.currentOrder.CLIENT_TEL}`}>
            {props.currentOrder.CLIENT_TEL}
          </NavLink>
        </h4>

        <p>{props.currentOrder.ORDER_COMMENT}</p>
        <h4>
          <b>{tempcart ? tempcart.length : 0}</b> élémént(s)
        </h4>

        <div className="BoxTotal">
          <div className="text1">Montant Total</div>
          <div className="value" devise="FCFA">
            {formatNumberWith(Number(props.amountSelectedOrder), ",")}
          </div>
        </div>
      </div>

      <div className="panierList">
        <div className="panierList__struct">
          {tempcart &&
            tempcart.map((item, index) => (
              <PanierItemAdmin
                key={index}
                id={item.id}
                item={item.qte}
                name={item.nom}
                price={item.price}
                comment={item.comment}
                handleClick={removeFromCart}
              ></PanierItemAdmin>
            ))}
          {tempcart && tempcart.length > 0 ? "" : "Votre Panier est vide"}
        </div>
      </div>

      {Number(props.currentOrder.ORDER_STATUS) === 1 && (
        <div onClick={handleValidate} className="btnAction">
          {" "}
          Valider la commande{" "}
        </div>
      )}

      {Number(props.currentOrder.ORDER_STATUS) === 1 && ( // Only show "Cancel" if status is 1
        <div onClick={handleCancel} className="btnAction btnActionCancel">
          {" "}
          Annuler la commande{" "}
        </div>
      )}



      <OrderTicket order={props.currentOrder} />



      <div
        className="btn_close rounded_btn btn_close_panier link"
        onClick={handleClickActions}
      >
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0327 8L15.5814 3.45136C16.1395 2.89318 16.1395 1.98818 15.5814 1.42955L14.5705 0.418636C14.0123 -0.139545 13.1073 -0.139545 12.5486 0.418636L8 4.96727L3.45136 0.418636C2.89318 -0.139545 1.98818 -0.139545 1.42955 0.418636L0.418636 1.42955C-0.139545 1.98773 -0.139545 2.89273 0.418636 3.45136L4.96727 8L0.418636 12.5486C-0.139545 13.1068 -0.139545 14.0118 0.418636 14.5705L1.42955 15.5814C1.98773 16.1395 2.89318 16.1395 3.45136 15.5814L8 11.0327L12.5486 15.5814C13.1068 16.1395 14.0123 16.1395 14.5705 15.5814L15.5814 14.5705C16.1395 14.0123 16.1395 13.1073 15.5814 12.5486L11.0327 8Z"
            fill="#FF6B01"
          />
        </svg>
      </div>
    </div>
  );
};

export default BagOrderAdmin;
