const BASE_URL = process.env.REACT_APP_BASE_ENDPOINT;
export const endpoints = {
    public: {
        createEstablishmentUser: `${BASE_URL}/public/establishment/`,
        updateEstablishment: `${BASE_URL}/public/user/updateestablishment/`,
        updateEstablishmentOpenDays: `${BASE_URL}/public/user/updateestablishmentopendays/`,
        getEstablishment: `${BASE_URL}/public/user/getestablishment`,
        userLogin: `${BASE_URL}/public/user/login`,
        userDeconnection: `${BASE_URL}/public/user/deconnection`,
        createUser: `${BASE_URL}/public/user/createuser`,
        userEstablishment: `${BASE_URL}/public/user/userestablishment`,
        fileDownload: `${BASE_URL}/public/user/download-file`,
        setUserState: `${BASE_URL}/public/user/setuserstate`,
        getCategoryByID: `${BASE_URL}/public/user/getcategorybyid`,
        getUserByID: `${BASE_URL}/public/user/getuserbyid`,
        getArticleById: `${BASE_URL}/public/user/getarticlebyid`,
        getAllArticlesByEstablishment: `${BASE_URL}/public/user/getallarticlesbyestablishment`,
        upadateCategory: `${BASE_URL}/public/user/updatecategory`,
        updateUser: `${BASE_URL}/public/user/updateuser`,
        setCategoryState: `${BASE_URL}/public/user/setcategorystate`,
        setMenuState: `${BASE_URL}/public/user/setmenustate`,
        deleteCategory: `${BASE_URL}/public/user/deletecategory`,
        deleteUser: `${BASE_URL}/public/user/deleteuser`,
        deleteArticle: `${BASE_URL}/public/user/deletearticle`,
        createCategory: `${BASE_URL}/public/user/createcategory`,
        getAllCategory: `${BASE_URL}/public/user/getallcategory`,
        createArticle: `${BASE_URL}/public/user/createarticle`,
        updateArticle: `${BASE_URL}/public/user/updatearticle`,
        incrementArticleView: `${BASE_URL}/public/user/incrementarticleview`,
        loadEstablishment: `${BASE_URL}/public`,
        createOrder: `${BASE_URL}/public/user/createorder`,
        stats: `${BASE_URL}/public/user/stats`,
        getOrders: `${BASE_URL}/public/user/orders`,
        getOrdersSales: `${BASE_URL}/public/user/orderssales`,
        setOrderStatus: `${BASE_URL}/public/user/setorderstatus`,
        validatenumber: `https://api.ipgeolocation.io/ipgeo?apiKey=d1fd4aa7f70947bf841e01067e5a3697`,
        subscribeNotification: `${BASE_URL}/public/user/notificationsubscription`,
        product: (id) => `/product/${id}`,
        register: "/auth/register",
        login: "/auth/login",
        me: "/auth/me",
        logout: "/auth/logout",
        order: "/order",
    },
    private: {
        productList: "/product",
        product: (id) => `/product/${id}`,
        register: "/auth/register",
        login: "/auth/login",
        me: "/auth/me",
        logout: "/auth/logout",
        order: "/order",
    }

};

