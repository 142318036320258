import React from "react";
import { Helmet } from 'react-helmet-async';

const MetaTags = ({ title, description, imageUrl, siteUrl }) => {

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title >{title}</title>
      <meta name="title" content={title}  data-nosnippet />
      <meta name="description" content={description} data-nosnippet />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" data-nosnippet />
      <meta property="og:url" content={siteUrl} data-nosnippet />
      <meta property="og:title" content={title} data-nosnippet />
      <meta property="og:description" content={description} data-nosnippet />
      <meta property="og:image" content={imageUrl} data-nosnippet />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" data-nosnippet />
      <meta property="twitter:url" content={siteUrl} data-nosnippet />
      <meta property="twitter:title" content={title} data-nosnippet />
      <meta property="twitter:description" content={description} data-nosnippet />
      <meta property="twitter:image" content={imageUrl} data-nosnippet />
    </Helmet>
  );
};

export default MetaTags;