







import { Howl } from 'howler';
import io from 'socket.io-client';
import Swal from 'sweetalert2';
import { getUserData } from './utils';

// Fonction pour jouer un son
function jouerSonnerie(url) {
  return new Howl({
    src: [url],
    autoplay: true,
    loop: true,
    volume: 1.0,
  });
}

// Enregistrement du Service Worker et écoute des messages
navigator.serviceWorker.addEventListener('message', () => {
  console.log('Service Worker: Jouer la sonnerie');
  jouerSonnerie('https://storage.googleapis.com/uploadregestfiles/assets/Doorbell%20chime%20sound%20effect%20%E2%80%A2%20Uppbeat.mp3');
});

// Fonction pour arrêter un son
function arreterSon(sound) {
  if (sound) {
    sound.stop();
  }
}

// Fonction d'enregistrement du Service Worker
async function registerSW() {
  return await navigator.serviceWorker.getRegistration();
}

let registration = await registerSW();

const showNotification = (bodyE) => {
  const imgFirstItem = JSON.parse(bodyE.ORDER_DATA)[0].ARTICLE_IMG_1;
  const payload = {
    data: {
      title: `Nouvelle Commande: ${bodyE.ORDER_TYPE} !`,
      body: 'Vous avez reçu une nouvelle commande.',
      toPage: 'https://resgest.com/admin/app/orders',
    },
    actions: [{ action: 'Ouvrir', title: 'Voir la commande...' }],
    body: 'Vous avez reçu une nouvelle commande.',
    image: imgFirstItem,
    icon: 'https://storage.googleapis.com/uploadregestfiles/assets/icon-192x192.png',
    badge: 'https://storage.googleapis.com/uploadregestfiles/assets/badgeresgest.png',
    title: bodyE.title,
    renotify: true,
    requireInteraction: true,
    tag: bodyE.ORDER_ID,
  };

  if (registration?.showNotification) {
    registration.showNotification(`Nouvelle Commande: ${bodyE.ORDER_TYPE} !`, payload);
  } else {
    new Notification(`Nouvelle Commande: ${bodyE.ORDER_TYPE} !`, payload);
  }
};

class WebSocketClient {
  constructor(url, reconnectTimeout = 5000) {
    this.url = url;
    this.reconnectTimeout = reconnectTimeout;
    this.currentSound = null;
    this.socket = this.initSocket();
  }

  initSocket() {
    const socket = io(this.url, {
      reconnectionAttempts: Infinity,
      reconnectionDelay: this.reconnectTimeout,
    });

    socket.on('connect', this.handleConnect.bind(this));
    socket.on('disconnect', this.handleDisconnect.bind(this));
    socket.on('connect_error', this.handleError.bind(this));
    socket.on('message', this.handleMessage.bind(this));

    return socket;
  }

  handleConnect() {
    console.log('Connecté au serveur Socket.IO');
  }

  handleDisconnect() {
    console.log('Déconnecté du serveur Socket.IO');
    this.reconnect();
  }

  handleError(error) {
    console.error('Erreur Socket.IO:', error);
    this.reconnect();
  }

  async handleMessage(reponse) {

    if (this.shouldHandleMessage(reponse)) {

      arreterSon(this.currentSound);

      const { data: obj } = reponse;
      console.log('NEW ORDER:', obj);

      if ('Notification' in window) {
        if (Notification.permission === 'granted') {
          showNotification(obj);
        }
      } else {
        // The browser does not support the Notification API
        console.warn('Notifications not supported in this browser');
      }

      this.currentSound = jouerSonnerie('https://storage.googleapis.com/uploadregestfiles/assets/Doorbell%20chime%20sound%20effect%20%E2%80%A2%20Uppbeat.mp3');

      await Swal.fire({
        icon: 'success',
        title: `Nouvelle Commande: ${obj.ORDER_TYPE}!`,
        background: '#fff',
        text: 'Allez à l\'onglet commande pour afficher la commande',
        backdrop: `
          rgba(0,0,123,0.4)
          url('https://storage.googleapis.com/uploadregestfiles/assets/nyan-cat.gif')
          left top
          no-repeat
        `,
        confirmButtonText: 'OK',
        willClose: () => arreterSon(this.currentSound),
      });
    }
  }

  reconnect() {
    setTimeout(() => {
      console.log('Tentative de reconnexion au serveur Socket.IO...');
      this.socket = this.initSocket();
    }, this.reconnectTimeout);
  }

  send(message) {
    if (this.socket?.connected) {
      this.socket.emit('message', message);
    } else {
      console.error('Socket.IO non connecté');
    }
  }

  shouldHandleMessage(reponse) {
    const currentUser = JSON.parse(localStorage.getItem('CurrentUser'));
    return (
      currentUser &&
      reponse.event === 'createOrder____________Backend' &&
      window.location.href.includes('admin/app') &&
      currentUser.ESTABLISSEMENT.ESTABLISHMENT_ID === reponse.data.ESTABLISHMENT_ID
    );
  }
}

const wsClient = new WebSocketClient(process.env.REACT_APP_WEBSOCKET_SERVER);
export default wsClient;
