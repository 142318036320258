
import React from 'react';
import { connect } from "react-redux";
import { handle_checkout_pop, handle_menu_details, handle_finalize, get_Basket_Amount } from "../../redux/action";



function Panier(props) {

    let nbreItem = 0;
    let initNbreItem = 0;
    const cart = JSON.parse(localStorage.getItem("cart"));
  
    if (cart) {
      cart.map(
        (item,index) => (initNbreItem = initNbreItem + 1)
      );
    }

    nbreItem=initNbreItem;

    const handleClickActions = ()=>{
      props.handleClick();
      // props.get_Basket_Amount();
    }

    return (
      <div className="panier_ico" item={nbreItem} onClick={handleClickActions}>
        <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_32_193)">
            <path
              d="M13 5.06257V5.43757C13 5.74823 12.7575 6.00007 12.4583 6.00007H12.2778L11.6884 10.2842C11.6122 10.8384 11.1551 11.2501 10.616 11.2501H2.38401C1.84489 11.2501 1.38782 10.8384 1.31156 10.2842L0.722222 6.00007H0.541667C0.242509 6.00007 0 5.74823 0 5.43757V5.06257C0 4.7519 0.242509 4.50007 0.541667 4.50007H2.06152L4.47148 1.05895C4.70609 0.72398 5.15775 0.649895 5.48036 0.893527C5.80294 1.13716 5.87426 1.60621 5.63965 1.94121L3.84757 4.50007H9.15243L7.36035 1.94118C7.12574 1.60621 7.19708 1.13714 7.51966 0.893504C7.8422 0.649871 8.29393 0.723934 8.52854 1.05893L10.9385 4.50007H12.4583C12.7575 4.50007 13 4.7519 13 5.06257ZM7.04167 9.18757V6.56257C7.04167 6.2519 6.79916 6.00007 6.5 6.00007C6.20084 6.00007 5.95833 6.2519 5.95833 6.56257V9.18757C5.95833 9.49823 6.20084 9.75007 6.5 9.75007C6.79916 9.75007 7.04167 9.49823 7.04167 9.18757ZM9.56944 9.18757V6.56257C9.56944 6.2519 9.32694 6.00007 9.02778 6.00007C8.72862 6.00007 8.48611 6.2519 8.48611 6.56257V9.18757C8.48611 9.49823 8.72862 9.75007 9.02778 9.75007C9.32694 9.75007 9.56944 9.49823 9.56944 9.18757ZM4.51389 9.18757V6.56257C4.51389 6.2519 4.27138 6.00007 3.97222 6.00007C3.67306 6.00007 3.43056 6.2519 3.43056 6.56257V9.18757C3.43056 9.49823 3.67306 9.75007 3.97222 9.75007C4.27138 9.75007 4.51389 9.49823 4.51389 9.18757Z"
              fill="#FF6B01"
            />
          </g>
          <defs>
            <clipPath id="clip0_32_193">
              <rect width={13} height={12} fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }


const mapStateToProps = (state) => ({
  isCheckout: state.isCheckout,
  isMenuDetails : state.isMenuDetails,
  isFinalize : state.isFinalize,
  basketAmount: state.basketAmount
});

const mapDispatchToProps = {
  handle_checkout_pop,
  handle_menu_details,
  handle_finalize,
  get_Basket_Amount
};
export default connect(mapStateToProps, mapDispatchToProps)(Panier);