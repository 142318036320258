// reducers.js
const getCurrentBasketAmount = () =>{
  let amountBasket = 0;

  const cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];

  if (cart && cart.length !==0 ) {
    cart.map(
      (item) => (amountBasket = amountBasket + Number(item.price))
    );
    return amountBasket;
  }else{
    return 0;
  }
} 


const initialState = {
  count: 45,
  basketAmount: getCurrentBasketAmount(),
  isMenuActive: false,
  USER_DATA: "dfdf",
  isCheckout: false,
  isMenuDetails: false,
  isArticleFromLink: false,
  isFinalize:false,
  currentArticle:{}
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INCREMENT":
      console.log(state.count + 1);
      return { ...state, count: state.count + 1 };


    case "DECREMENT":
      console.log(state.count - 1);
      return { ...state, count: state.count - 1 };


    case "HANDLE_CHECKOUT_POP":
      console.log("HANDLE_CHECKOUT_POP", !state.isCheckout);
      return { ...state, isCheckout: !state.isCheckout, isFinalize: false, isArticleFromLink:false , basketAmount: getCurrentBasketAmount() };


    case "HANDLE_MENU_DETAILS":
      console.log("HANDLE_MENU_DETAILS", !state.isMenuDetails);
      return { ...state, isMenuDetails: !state.isMenuDetails, isCheckout: false, isFinalize: false, isArticleFromLink:false , basketAmount: getCurrentBasketAmount() };

    case "OPEN_MENU_DETAILS":
      console.log("OPEN_MENU_DETAILS isArticleFromLink", true);
      return { ...state, isMenuDetails: false, isCheckout: false, isFinalize: false ,  isArticleFromLink: true , basketAmount: getCurrentBasketAmount() };

    case "CLOSE_MENU_DETAILS":
      console.log("CLOSE_MENU_DETAILS isArticleFromLink", false);
      return { ...state, isMenuDetails: false, isCheckout: false, isFinalize: false ,  isArticleFromLink: false , basketAmount: getCurrentBasketAmount() };


    case "HANDLE_FINALIZE":
      console.log("HANDLE_FINALIZE", !state.isFinalize);
      return { ...state, isFinalize: !state.isFinalize,  isCheckout: false, isMenuDetails: false,  isArticleFromLink:false , basketAmount: getCurrentBasketAmount() };

    case "HANDLE_GO_HOME":
      console.log("HANDLE_GO_HOME");
      return { ...state, isFinalize: false,  isCheckout: false, isMenuDetails: false, isArticleFromLink:false , basketAmount: getCurrentBasketAmount() };


    case "GET_BASKET_AMOUNT":
      console.log("GET_BASKET_AMOUNT", state.basketAmount);
      return { ...state, basketAmount: getCurrentBasketAmount()};

    case "HANDLE_CLICK_ARTICLE":
      console.log("HANDLE_CLICK_ARTICLE currentSelectedArticle :",state.currentArticle);
      return { ...state, currentArticle: (article) =>(JSON.parse(article))};


    case "USERCONNECTION":
      console.log(
        "CurrentUser IN REDUCER.JS",
        JSON.parse(localStorage.getItem("CurrentUser"))
      );
      return {
        ...state,
        USER_DATA: JSON.parse(localStorage.getItem("CurrentUser")),
      };


    case "MENUIO":
      console.log(!state.isMenuActive);
      return { ...state, isMenuActive: !state.isMenuActive };
    default:
      return state;
  }
};

export default rootReducer;
