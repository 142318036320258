import React, {useRef, useState, useEffect} from "react";

const ImageComponent = (props) => {
    const imageRef = useRef(null);
    const [imageHeight, setImageHeight] = useState(0);
  
    // Chargement de l'image lorsque le composant est monté
    useEffect(() => {
      if (imageRef.current) {
        const image = imageRef.current;
        setImageHeight(image.naturalHeight);
      }
    }, [imageRef]);
  
    return (
      <div>
        <img ref={imageRef} src={props.url} height={imageHeight} alt={props.alt} />
      </div>
    );
  };

  export default  ImageComponent;