import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { formatNumberWith } from "../../utils";
import { getUserData } from "../../utils";
import { getOrdersSales } from "../API/api";

const nomDuMois = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

function formaterDate(timestamp) {
  // Créer une instance Date à partir du timestamp
  const date = new Date(Number(timestamp)); // Convertir en millisecondes

  // Obtenir les informations de date
  const heures = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const jour = date.getDate();
  const mois = date.getMonth(); // Janvier est 0, donc on ajoute 1
  const annee = date.getFullYear();

  // Formatter la date
  const dateFormatee = `${heures}:${minutes}, ${jour} ${nomDuMois[mois]} ${annee}`;

  return dateFormatee;
}

function OrderItem(props) {
  return (
    <tr>
      <td>
        {props.AudioPlayer}
        <div className="btnAction">
          <div
            onClick={() => props.handleViewOrder(props.order)}
            className="btnAction__item btnAction--view"
          >
            View
          </div>
        </div>
      </td>
      <td>{formatNumberWith(Math.floor(props.order.ORDER_AMOUNT), " ")}</td>{" "}
      {/* Fixed typo in `ORDER_AMOUNT` */}
      <td>
        <span
          className={`state ${
            Number(props.order.ORDER_STATUS) === 1
              ? "pending"
              : Number(props.order.ORDER_STATUS) === 2
              ? "sale"
              : "cancel"
          }`}
        >
          {`${
            Number(props.order.ORDER_STATUS) === 1
              ? "pending"
              : Number(props.order.ORDER_STATUS) === 2
              ? "sale"
              : "cancel"
          }`}
        </span>
      </td>
      <td>
        {Number(props.order.SERVICE_TYPE) === 1
          ? "Manger sur place"
          : "Emporter"}
      </td>
      <td>{props.order.ORDER_TYPE}</td>
      <td>
        <div className="t1">{props.order.CLIENT_NAME}</div>
      </td>
      <td>
        <NavLink to={`tel:${props.order.CLIENT_TEL}`} className="t2 link">
          {props.order.CLIENT_TEL}
        </NavLink>
      </td>
      <td>{formaterDate(props.order.UPDATEAT)}</td>
    </tr>
  );
}

const MAX_ITEMS_TO_SHOW = 10; // Number of items to display initially

function DisplayOrdersSale({ handleViewOrder }) {

  const [ordersData, setOrdersData] = useState(false); // State to store fetched orders
  const [isOrdersFetched, setIsOrdersFetched] = useState(false); // Renamed for clarity
  const [displayedItems, setDisplayedItems] = useState(
    ordersData ? ordersData.slice(0, MAX_ITEMS_TO_SHOW) : []
  );



  const handleShowMore = () => {
    const newDisplayedItems = displayedItems.concat(
      ordersData.slice(
        displayedItems.length,
        displayedItems.length + MAX_ITEMS_TO_SHOW
      )
    );
    setDisplayedItems(newDisplayedItems);
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight - 300; //for force condition
    const currentScrollY = window.scrollY;

    if (displayedItems.length < ordersData.length) {
      if (windowHeight + currentScrollY >= documentHeight) {
        console.log("displayedItems.length", displayedItems.length);
        console.log("orders.length", ordersData.length);

        handleShowMore();
        console.log("load more --------------------->");
        console.log("PAS ENCORE tout chargé");
      }
    } else {
      // console.log("J'ai tout chargé")
    }
  };

  window.addEventListener("scroll", handleScroll);





  useEffect(() => {
    const establishmentID = getUserData().ESTABLISSEMENT.ESTABLISHMENT_ID;
    const fetchData = async () => {
      try {
        console.log("establishmentID", establishmentID);
        const res = await getOrdersSales(establishmentID);
        if (res) {
          setOrdersData(res.data);
          setDisplayedItems(res.data.slice(0, MAX_ITEMS_TO_SHOW))
          console.log("Orders", res);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!isOrdersFetched) {
      fetchData();
      setIsOrdersFetched(true); // Set only once after fetching
    }
  }, [isOrdersFetched, ordersData]);


  return (
    <>
      {isOrdersFetched &&
        displayedItems.map((order, index) => (
          <OrderItem
            key={index}
            order={order}
            handleViewOrder={handleViewOrder}
          ></OrderItem>
        ))}
    </>
  );
}

export default DisplayOrdersSale;
