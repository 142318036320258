import { React, useRef, useState } from "react";
import Panier from "../components/Panier";
import MenuTimePreparation from "../components/MenuTimePreparation";
import MenuUserView from "../components/MenuUserView";

import BagOrder from "../components/BagOrder";
import { useEffect } from "react";
import { formatNumberWith } from "../../utils";
import { useNavigate } from "react-router-dom";

import { getArticleById } from "../../Admin/API/api";
import Swal from "sweetalert2";
import BtnPopCheckout from "../components/BtnPopCheckout";

import { connect } from "react-redux";
import {
  handle_checkout_pop,
  handle_menu_details,
  handle_finalize,
  get_Basket_Amount,
  handle_go_home,
  close_menu_details,
  open_menu_details,
} from "../../redux/action";

import ImageComponent from "../components/ImageComponent";
import MetaTags from "../components/MetaTags";

import toast from 'react-hot-toast';


const MenuDetailsPop = (props) => {
  const [cart, setCart] = useState([]);
  const precision = useRef();

  const [count, setCount] = useState(0); // Initial value set to 1
  const [comment, setComment] = useState({ comment: '' }); // Initial value set to 1

  const [amoutBasket, setAmoutBasket] = useState(props.basketAmount);

  const [isPanierDisplay, setIsPanierDisplay] = useState(false);
  const [supExpand, setSupExpand] = useState(false);

  const [currentArticle, setCurrentArticle] = useState(false);

  const [isLoad, setIsLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasLoadedData, setHasLoadedData] = useState(false); // Nouvelle variable d'état

  const [hasIncrement, setHasIncrement] = useState(false); // Nouvelle variable d'état

  const navigate = useNavigate();

  const [establishment_URL, article_ID] = [
    JSON.parse(localStorage.getItem("currentEstablishmentData"))
      .currentEstablishment.ESTABLISHMENT_LINK,
    props.selectedArticle,
  ];
  window.location.href.split("/details/");
  const establishment_link_path = establishment_URL;

  // console.log("establishment_link_path ===>", establishment_link_path);

  const url = window.location.href;
  const parsedURL = new URL(url);
  const queryString = parsedURL.search;

  const queryStringParams = new URLSearchParams(queryString); // Creates a URLSearchParams object
  const isFromHome = queryStringParams.get("prev") === "true"; // Access the 'prev' parameter value

  const displayPanier = () => {
    setIsPanierDisplay(true);
  };

  const closePanier = () => {
    setIsPanierDisplay(false);
  };

  const increment = () => {
    setCount(count + 1);
    // setAmoutBasket(menuPrice * (count + 1));
  };

  const handleClosePopUp = () => {
    if (props.isArticleFromLink) {
      props.close_menu_details();
    } else {
      props.handle_menu_details();
    }
  };

  const decrement = () => {
    if (count > 0) {
      setCount(count - 1);

      // setAmoutBasket(menuPrice * (count - 1));
    }
  };
  const notify = (msg) => toast.success(msg);
  const addToCart = () => {
    if (count > 0) {
      const item = {
        id: String(Date.now()), // ID aléatoire
        ARTCLE_ID: currentArticle.ARTICLE_ID,
        nom: currentArticle.ARTICLE_NAME,
        qte: count,
        price: Number(currentArticle.ARTICLE_PRICE) * count,
        time: Number(currentArticle.ARTICLE_PREPARE_TIME),
        devise: "FCFA",
        comment: comment.comment,
        ARTICLE_IMG_1: currentArticle.ARTICLE_IMG_1
      };
      const storageCart = localStorage.getItem("cart");
      if (storageCart === null) {
        localStorage.setItem("cart", JSON.stringify([item]));
        setCart([item]);
        setAmoutBasket(props.get_Basket_Amount());
        notify(`(${count < 10 ? '0' + count : count}) Ajouté au panier`);
      } else {
        const currentCart = JSON.parse(storageCart);
        localStorage.setItem("cart", JSON.stringify([...currentCart, item]));
        setCart([...currentCart, item]);
        setAmoutBasket(props.get_Basket_Amount());
        notify(`(${count < 10 ? '0' + count : count}) Ajouté au panier`);
      }
      console.log("LAST Value Cart", JSON.parse(localStorage.getItem("cart")));
    }


  };

  const fn_share = async () => {
    if (navigator.share) {
      const establishmentName = JSON.parse(
        localStorage.getItem("currentEstablishmentData")
      ).currentEstablishment.ESTABLISHMENT_NAME;

      navigator
        .share({
          title: `${establishmentName}: ${currentArticle.ARTICLE_NAME}`,
          text: currentArticle.ARTICLE_DESCRIPTION,
          url: `${window.location.origin}${window.location.pathname}#article=${currentArticle.ARTICLE_ID}`, //http://localhost:3000/nissi--restaurant-bbq#ART-01-05-24-1714587621659
        })
        .then(() => console.log("Partage réussi"))
        .catch((error) => {
          console.log("Erreur de partage", error);
        });
    } else {
      console.log(`Votre système ne prend pas en charge l'API de partage Web.`);
      Swal.fire({
        icon: "warning",
        title: "info",
        text: `Votre système ne prend pas en charge API de partage Web.`,
      });
    }
  };


  const requestFileAccess = async () => {
    const { granted } = await window.requestFileSystemAccess({ type: "read" });
    if (!granted) {
      console.error("Permission denied to access files");
      return;
    }

    // L'autorisation a été accordée, vous pouvez maintenant partager des fichiers
    fn_share();
  };

  useEffect(() => {
    const fn_getArticleById = async () => {
      setIsLoading(true);

      try {
        await getArticleById(article_ID).then((res) => {
          if (res.reponse === 1) {
            console.log("Result :", res);
            setCurrentArticle(res.data);

            setIsLoading(false);
            setIsLoad(true);
            setHasLoadedData(true);
          } else {
            window.location.hash = "";
            Swal.fire({
              icon: "question",
              title: "Error",
              text: res.message,
            });
            setError(res.message);
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error("Error fetching establishment data:", error);
        setError(error.message);
      }
    };

    if (!currentArticle) {
      if (props.isArticleFromLink && props.isArticleFromLink === true) {
        fn_getArticleById();
        console.log("CET ARTICLE PROVIENT D'UN LIEN");
      } else {
        setCurrentArticle(JSON.parse(localStorage.getItem("currentArticle")));
        setIsLoading(false);
        setIsLoad(true);
        setHasLoadedData(true);
        console.log("CET ARTICLE EST CHARGEE AU CLICK");
      }
    }

    return () => { };
  }, [
    currentArticle,
    hasIncrement,
    article_ID,
    props.basketAmount,
    props.isFromLink,
    props.isArticleFromLink,
  ]);
  useEffect(() => {
    if (isLoad) {
      const btn = document.querySelector(".addToBasket");
      if (count === 0) {
        btn.disabled = true;
        btn.style.opacity = ".3";
      } else {
        btn.disabled = false;
        btn.style.opacity = "1";
      }
      if (props.isArticleFromLink) {
        window.location.hash = "";
      }

    }

    return (timeOut) => {
      clearTimeout(timeOut);
    };
  }, [amoutBasket, isLoad, count, props.isArticleFromLink]);

  useEffect(() => {
    let intervalId = 0;

    const handleCarousel = () => {
      let currentIndex = 0;
      const carrousel = document.querySelectorAll(".carrousel .img");

      console.log("carrousel.length", carrousel.length);

      if (carrousel.length > 1) {
        intervalId = setInterval(() => {
          carrousel.forEach((img) => {
            img.style.opacity = 0;
            img.style.display = "none";
            img.style.transform = "scale(1.5)";
          });
          carrousel[currentIndex].style.opacity = 1;
          carrousel[currentIndex].style.display = "inline-block";
          carrousel[currentIndex].style.transform = "scale(1)";

          currentIndex = (currentIndex + 1) % carrousel.length;

          // setAmoutBasket(props.get_Basket_Amount())
        }, 5000);
      } else {
        carrousel.forEach((img) => {
          img.style.opacity = 1;
          img.style.display = "inline-block";
          img.style.transform = "scale(1)";
        });
      }
    };

    if (isLoad) {
      handleCarousel();
    }

    return () => clearInterval(intervalId);
  }, [isLoad]);

  return (
    <>
      {hasLoadedData && (
        <>
          <MetaTags
            title={currentArticle.ARTICLE_NAME}
            description={currentArticle.ARTICLE_DESCRIPTION}
            imageUrl={currentArticle.ARTICLE_IMG_1}
            siteUrl={`${window.location.origin}${window.location.pathname}#article=${currentArticle.ARTICLE_ID}`}
          ></MetaTags>

          <main className="menuDetails MenuDetailsPop">
            <div className="main_container">
              <div id="carrouselContainer" className="menu_img carrousel">
                {[
                  currentArticle.ARTICLE_IMG_1,
                  currentArticle.ARTICLE_IMG_2,
                ].map((imageUrl, index) => {
                  if (imageUrl && imageUrl !== "") {
                    return <div className="img" key={index}>
                      <img src={imageUrl} alt={currentArticle.ARTICLE_NAME} />
                    </div>
                  } else {
                    return null;
                  }
                })}
              </div>
              <div id="menuInfo" className="menuInfo">
                <div className="menuInfo__struct">
                  <div className="actionMenuPage">
                    <div onClick={fn_share} className="btn_share rounded_btn ">
                      <svg
                        width={20}
                        height={18}
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.1452 6.40814L12.5632 0.724419C11.9871 0.226857 11.0791 0.630778 11.0791 1.40369V4.3974C5.07208 4.46617 0.308838 5.67008 0.308838 11.3628C0.308838 13.6605 1.78903 15.9368 3.42521 17.1268C3.93578 17.4982 4.66345 17.0321 4.47519 16.4301C2.77948 11.0071 5.27948 9.56741 11.0791 9.48397V12.7717C11.0791 13.5458 11.9878 13.9479 12.5632 13.451L19.1452 7.76669C19.5592 7.4091 19.5597 6.76621 19.1452 6.40814Z"
                          fill="white"
                        />
                      </svg>
                    </div>

                    <div
                      onClick={handleClosePopUp}
                      className="btn_close rounded_btn"
                    >
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.0327 8L15.5814 3.45136C16.1395 2.89318 16.1395 1.98818 15.5814 1.42955L14.5705 0.418636C14.0123 -0.139545 13.1073 -0.139545 12.5486 0.418636L8 4.96727L3.45136 0.418636C2.89318 -0.139545 1.98818 -0.139545 1.42955 0.418636L0.418636 1.42955C-0.139545 1.98773 -0.139545 2.89273 0.418636 3.45136L4.96727 8L0.418636 12.5486C-0.139545 13.1068 -0.139545 14.0118 0.418636 14.5705L1.42955 15.5814C1.98773 16.1395 2.89318 16.1395 3.45136 15.5814L8 11.0327L12.5486 15.5814C13.1068 16.1395 14.0123 16.1395 14.5705 15.5814L15.5814 14.5705C16.1395 14.0123 16.1395 13.1073 15.5814 12.5486L11.0327 8Z"
                          fill="#FF6B01"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="menu_stat">
                    <MenuTimePreparation
                      time={currentArticle.ARTICLE_PREPARE_TIME}
                    ></MenuTimePreparation>
                    <MenuUserView
                      views={currentArticle.ARTICLE_VIEW}
                    ></MenuUserView>
                  </div>

                  <h2
                    className="menu_title"
                    menutimepreparation={currentArticle.ARTICLE_PREPARE_TIME}
                  >
                    {currentArticle.ARTICLE_NAME}
                  </h2>


                  <div className="inputPrecise">
                    <div className="label">ajouter un commentaire</div>
                    <input name="comment" onChange={(e) => { setComment((prev) => { return { ...prev, [e.target.name]: e.target.value } }) }} value={comment.comment} type="text" placeholder="Faites une précision ici..." />
                  </div>


                  <div className="acountable">
                    <div className="pricing">
                      <span
                        className="price"
                        value={currentArticle.ARTICLE_PRICE}
                        device="FCFA"
                      >
                        {formatNumberWith(
                          Number(currentArticle.ARTICLE_PRICE),
                          ","
                        )}
                      </span>
                    </div>

                    <div className="form">
                      <div className="input_box">
                        <div className="btnInput subtract" onClick={decrement}>
                          <svg
                            width={6}
                            height={3}
                            viewBox="0 0 6 3"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.57143 0.84375H0.428571C0.19192 0.84375 0 1.03967 0 1.28125V1.71875C0 1.96033 0.19192 2.15625 0.428571 2.15625H5.57143C5.80808 2.15625 6 1.96033 6 1.71875V1.28125C6 1.03967 5.80808 0.84375 5.57143 0.84375Z"
                              fill="#FF6B01"
                            />
                          </svg>
                        </div>
                        <div className="input qte">{count}</div>
                        <div className="btnInput add" onClick={increment}>
                          <svg
                            width={5}
                            height={6}
                            viewBox="0 0 5 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.64286 2.4375H3.03571V0.75C3.03571 0.54293 2.87578 0.375 2.67857 0.375H2.32143C2.12422 0.375 1.96429 0.54293 1.96429 0.75V2.4375H0.357143C0.159933 2.4375 0 2.60543 0 2.8125V3.1875C0 3.39457 0.159933 3.5625 0.357143 3.5625H1.96429V5.25C1.96429 5.45707 2.12422 5.625 2.32143 5.625H2.67857C2.87578 5.625 3.03571 5.45707 3.03571 5.25V3.5625H4.64286C4.84007 3.5625 5 3.39457 5 3.1875V2.8125C5 2.60543 4.84007 2.4375 4.64286 2.4375Z"
                              fill="#73B704"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="addToBasket" onClick={addToCart}>
                        Ajouter
                      </div>
                    </div>



                  </div>





                </div>
                {/*
                <div className="addonSection" style={{ height: supExpand ? "auto" : "70px" }}>
                   <div className="struct">
                    <div className="headers">
                      <div className="title">Supplements</div>
                      <div className="action" onClick={() => { setSupExpand(!supExpand) }}>
                        {
                          supExpand ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color={"#000000"} fill={"none"}>
                            <path d="M20 12L4 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" color={"#000000"} fill={"none"}>
                              <path d="M12 4V20M20 12H4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                      </div>
                    </div>

                    <div className="containerAddon">
                      <div className="addons">
                        <div className="addon">
                          <div className="img">
                            <img src={currentArticle.ARTICLE_IMG_1} alt="" />
                          </div>
                          <div className="addon_name">
                            {currentArticle.ARTICLE_NAME}
                          </div>

                          <div className="addon">
                            <div className="img">
                              <img src={currentArticle.ARTICLE_IMG_1} alt="" />
                            </div>
                            <div className="addon_name">
                              {currentArticle.ARTICLE_NAME}
                            </div>
                          </div>
                          <div className="addon">
                            <div className="img">
                              <img src={currentArticle.ARTICLE_IMG_1} alt="" />
                            </div>
                            <div className="addon_name">
                              {currentArticle.ARTICLE_NAME}
                            </div>
                          </div>
                          <div className="addon">
                            <div className="img">
                              <img src={currentArticle.ARTICLE_IMG_1} alt="" />
                            </div>
                            <div className="addon_name">
                              {currentArticle.ARTICLE_NAME}
                            </div>
                          </div>
                          <div className="addon">
                            <div className="img">
                              <img src={currentArticle.ARTICLE_IMG_1} alt="" />
                            </div>
                            <div className="addon_name">
                              {currentArticle.ARTICLE_NAME}
                            </div>
                          </div>
                          <div className="addon">
                            <div className="img">
                              <img src={currentArticle.ARTICLE_IMG_1} alt="" />
                            </div>
                            <div className="addon_name">
                              {currentArticle.ARTICLE_NAME}
                            </div>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div> 



                </div>*/}

                <div className="menuInfo__struct">
                  <pre className="MenuDescription">
                    {currentArticle.ARTICLE_DESCRIPTION}
                  </pre>
                </div>

                <div className="main_struct"></div>
              </div>
              <Panier handleClick={displayPanier} item="05"></Panier>
              <div className="main_struct"></div>
              <BtnPopCheckout
                to={`/${establishment_link_path}/checkout`}
              ></BtnPopCheckout>
              {isPanierDisplay && <BagOrder handleClick={closePanier}></BagOrder>}{" "}
            </div>
          </main>
        </>
      )}
      {error && <div>Error: {error}</div>}
    </>
  );
};

const mapStateToProps = (state) => ({
  isCheckout: state.isCheckout,
  isMenuDetails: state.isMenuDetails,
  isFinalize: state.isFinalize,
  basketAmount: state.basketAmount,
  isArticleFromLink: state.isArticleFromLink,
});

const mapDispatchToProps = {
  handle_checkout_pop,
  handle_menu_details,
  handle_finalize,
  get_Basket_Amount,
  handle_go_home,
  close_menu_details,
  open_menu_details,
};
export default connect(mapStateToProps, mapDispatchToProps)(MenuDetailsPop);
