export const increment = () => ({
    type: 'INCREMENT',
  });
  
  export const decrement = () => ({
    type: 'DECREMENT',
  });

  export const menuio = () => ({
    type: 'MENUIO',
  });
  
  export const handle_checkout_pop = () => ({
    type: 'HANDLE_CHECKOUT_POP',
  });

  export const handle_menu_details = () => ({
    type: 'HANDLE_MENU_DETAILS',
  });

  export const handle_finalize = () => ({
    type: 'HANDLE_FINALIZE',
  });

  export const get_Basket_Amount = () => ({
    type: 'GET_BASKET_AMOUNT',
  });

  export const handle_go_home = () => ({
    type: 'HANDLE_GO_HOME',
  });
  
  export const open_menu_details = () => ({
    type: 'OPEN_MENU_DETAILS',
  });

  export const close_menu_details = () => ({
    type: 'CLOSE_MENU_DETAILS',
  });

  export const set_current_Article = () => ({
    type: 'HANDLE_CLICK_ARTICLE',
  });

  export const userconnection = () => ({
    type: 'USERCONNECTION',
  });