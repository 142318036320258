import React from 'react';
import "./App.scss";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import PushNotificationManager from './PushNotificationManager';

import { getEstablishmentData } from './utils';



function App() {

  return (
    <HelmetProvider>
      <Provider store={store}>
        <PushNotificationManager />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>

      </Provider>
    </HelmetProvider>
  );
}

export default App;

