import React, { useEffect, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { formatNumberWith } from "../../utils";
import { getOrders } from "../API/api";
import { getUserData } from "../../utils";
import { useWebSocket } from "../../webSocketContext"; // Assurez-vous que le chemin est correct
import Swal from "sweetalert2";

const nomDuMois = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "août",
  "septembre",
  "octobre",
  "novembre",
  "décembre",
];

function formaterDate(timestamp) {
  const date = new Date(Number(timestamp));
  const heures = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const jour = date.getDate();
  const mois = date.getMonth();
  const annee = date.getFullYear();
  return `${heures}:${minutes}, ${jour} ${nomDuMois[mois]} ${annee}`;
}

function OrderItem(props) {
  return (
    <tr>
      <td>
        <div className="btnAction">
          <div
            onClick={() => props.handleViewOrder(props.order)}
            className="btnAction__item btnAction--view"
          >
            View
          </div>
        </div>
      </td>
      <td>{formatNumberWith(Math.floor(props.order.ORDER_AMOUNT), " ")}</td>
      <td>
        <span
          className={`state ${
            Number(props.order.ORDER_STATUS) === 1
              ? "pending"
              : Number(props.order.ORDER_STATUS) === 2
              ? "sale"
              : "cancel"
          }`}
        >
          {`${
            Number(props.order.ORDER_STATUS) === 1
              ? "pending"
              : Number(props.order.ORDER_STATUS) === 2
              ? "sale"
              : "cancel"
          }`}
        </span>
      </td>
      <td>
        {Number(props.order.SERVICE_TYPE) === 1
          ? "Manger sur place"
          : "Emporter"}
      </td>
      <td>{props.order.ORDER_TYPE}</td>
      <td>
        <div className="t1">{props.order.CLIENT_NAME}</div>
      </td>
      <td>
        <NavLink to={`tel:${props.order.CLIENT_TEL}`} className="t2 link">
          {props.order.CLIENT_TEL}
        </NavLink>
      </td>
      <td>{formaterDate(props.order.UPDATEAT)}</td>
    </tr>
  );
}

const MAX_ITEMS_TO_SHOW = 10;

function DisplayOrders({ handleViewOrder }) {
  const wsClient = useWebSocket();

  const [ordersData, setOrdersData] = useState([]);
  const [isOrdersFetched, setIsOrdersFetched] = useState(false);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [notify, setNotify] = useState(false);

  const [newOrder, setNewOrder] = useState(String(Date.now()));

  const establishmentID = getUserData().ESTABLISSEMENT.ESTABLISHMENT_ID;

  const handleShowMore = useCallback(() => {
    const newDisplayedItems = displayedItems.concat(
      ordersData.slice(
        displayedItems.length,
        displayedItems.length + MAX_ITEMS_TO_SHOW
      )
    );
    setDisplayedItems(newDisplayedItems);
  }, [displayedItems, ordersData]);

  const handleScroll = useCallback(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight - 300;
    const currentScrollY = window.scrollY;

    if (displayedItems.length < ordersData.length) {
      if (windowHeight + currentScrollY >= documentHeight) {
        handleShowMore();
      }
    }
  }, [displayedItems.length, ordersData.length, handleShowMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const establishmentID = getUserData().ESTABLISSEMENT.ESTABLISHMENT_ID;
        const res = await getOrders(establishmentID);
        if (res) {
          setOrdersData(res.data);
          setDisplayedItems(res.data.slice(0, MAX_ITEMS_TO_SHOW));
          setIsOrdersFetched(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!isOrdersFetched) {
      fetchData();
    }
  }, [isOrdersFetched]);

  // useEffect(() => {
  //   const handleMessage = async (reponse) => {
  //     if (
  //       reponse.data.ESTABLISHMENT_ID === establishmentID &&
  //       reponse.event === "createOrder____________Backend" &&
  //       window.location.href.includes("admin/app")
  //     ) {
  //       console.log("reponse.data", reponse.data);
  //       setIsOrdersFetched(false);
  //       setNotify(true);

  //       await Swal.fire({
  //         icon: "success",
  //         title: "Nouvelle <br> Commande !",
  //         background: "#fff",
  //         text: "Allez à l'onglet commande pour afficher la commande",
  //         backdrop: `
  //           rgba(0,0,123,0.4)
  //           url('https://storage.googleapis.com/uploadregestfiles/assets/nyan-cat.gif')
  //           left top
  //           no-repeat
  //         `,
  //       });
  //     } else if (
  //       reponse.data.ESTABLISHMENT_ID === establishmentID &&
  //       reponse.event === "updateOrder____________Backend" &&
  //       window.location.href.includes("admin/app")
  //     ) {
  //       setIsOrdersFetched(false);
  //     }
  //   };

  //   if (wsClient && wsClient.socket) {
  //     wsClient.socket.on("message", handleMessage);
  //   }

  //   return () => {
  //     if (wsClient && wsClient.socket) {
  //       wsClient.socket.off("message", handleMessage);
  //     }
  //   };
  // }, [wsClient]);



  const handleMessage = async (reponse) => {
    if (
      reponse.data.ESTABLISHMENT_ID === establishmentID &&
      reponse.event === "createOrder____________Backend" &&
      window.location.href.includes("admin/app")
    ) {
      console.log("reponse.data IN Display Order Component", reponse.data);

      setDisplayedItems([reponse.data,...displayedItems]);

      // setIsOrdersFetched(false);
      // setNotify(true);

      // await Swal.fire({
      //   icon: "success",
      //   title: "Nouvelle <br> Commande !",
      //   background: "#fff",
      //   text: "Allez à l'onglet commande pour afficher la commande",
      //   backdrop: `
      //     rgba(0,0,123,0.4)
      //     url('https://storage.googleapis.com/uploadregestfiles/assets/nyan-cat.gif')
      //     left top
      //     no-repeat
      //   `,
      // });
    } else if (
      reponse.data.ESTABLISHMENT_ID === establishmentID &&
      reponse.event === "updateOrder____________Backend" &&
      window.location.href.includes("admin/app")
    ) {
      setIsOrdersFetched(false);
    }
  };


  if (wsClient && wsClient.socket) {
    wsClient.socket.on("message", handleMessage);
  }


  return (
    <>
      {isOrdersFetched &&
        displayedItems.map((order, index) => (
          <OrderItem
            key={index}
            order={order}
            handleViewOrder={handleViewOrder}
          />
        ))}
    </>
  );
}

export default DisplayOrders;
