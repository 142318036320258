import React from "react";
import imgFinal from "../images/3D Food Icon by  final.webp";
import CountDownTimer from "../components/CountDownTimer";
import { connect } from "react-redux";
import { handle_checkout_pop, handle_menu_details, handle_finalize, handle_go_home } from "../../redux/action";


const Finalize = (props) => {
  return (
    <main className="menuDetails Checkout Finalize FinalizePop">
      <div className="main_struct">
        <div className="image">
          <img src={imgFinal} alt={imgFinal} />
        </div>

        <div className="container">
          <h2>Nous avons reçu votre Commande</h2>
          <p>
            Restez sur cette page pour suivre en tant réel l’évolution de votre
            commande
          </p>

          <CountDownTimer ></CountDownTimer>

          <div onClick={props.handle_go_home} className="bnt link">
            Allez à la page d'acceuil
          </div>
        </div>
      </div>
    </main>
  );
};


const mapStateToProps = (state) => ({
  isCheckout: state.isCheckout,
  isMenuDetails : state.isMenuDetails,
  isFinalize : state.isFinalize,
});

const mapDispatchToProps = {
  handle_checkout_pop,
  handle_menu_details,
  handle_finalize,
  handle_go_home
};
export default connect(mapStateToProps, mapDispatchToProps)(Finalize);

