import React, { useState, useEffect, useRef } from "react";
import { getOrders } from "../API/api";
import { getUserData } from "../../utils";
import { setOrderStatus } from "../API/api";
import BagOrderAdmin from "../../menuApp/components/BagOrderAdmin";
import DisplayOrders from "./DisplayOrders";
import DisplayOrdersSale from "./DisplayOrderSale";

const LiveOrderSale = (props) => {
  const param = props.title ? props.title : "Live Order Management";

  const [isPanierDisplay, setIsPanierDisplay] = useState(false);
  const [orders, setOrders] = useState(props.orders);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [amountSelectedOrder, setAmountSelectedOrder] = useState(0);

  const establishmentID = getUserData().ESTABLISSEMENT.ESTABLISHMENT_ID;

  const closePanier = () => {
    setIsPanierDisplay(false);
  };

  const handleViewOrder = (order) => {
    console.log("current");

    let initialAmount = 0;
    const cart = JSON.parse(order.ORDER_DATA);
    if (cart) {
      cart.map((item) => (initialAmount = initialAmount + Number(item.price)));
    }

    setAmountSelectedOrder(initialAmount);

    setSelectedOrder(order);
    setIsPanierDisplay(true);
  };

  const fn_setOrderStatus = async (order, setStatus) => {
    try {
      const sendFormData = new FormData();
      sendFormData.append("ORDER", JSON.stringify(order));
      sendFormData.append("ORDER_STATUS", setStatus);

      setOrderStatus(sendFormData).then(async (res) => {
        if (res && res.reponse === 1) {
          console.log("setStatus", res);
          if (props.refreschDashboard) props.refreschDashboard();
        } else {
          console.log("setStatus", res);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidateOrder = async (order) => {
    await fn_setOrderStatus(order, 2);
    if (props.fetchStats_temp) {
      await props.fetchStats_temp();
    }
  };

  const handleCancelOrder = async (order) => {
    await fn_setOrderStatus(order, 3);
  };

  return (
    <div className="section section4">
      <div className="orderTab">
        <div className="head_statItem">
          <span className="title"> {param}</span>
          <select name="time" id="">
            <option value="This month">This month</option>
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
          </select>
        </div>

        <div className="table_container">
          <div className="table_container__innerContainer">
            <table className="table">
              <tbody>
                <tr className="thead">
                  <td>Actions</td>
                  <td>Amount</td>
                  <td>Order Status</td>
                  <td>Service</td>
                  <td ordertype="Client Table">Client Table</td>
                  <td>Client Name</td>
                  <td>Client Phone</td>

                  <td>Date</td>
                </tr>

                <DisplayOrdersSale
                    handleViewOrder={handleViewOrder}
                  ></DisplayOrdersSale>

              </tbody>
            </table>
          </div>
        </div>

        <div className="actionbtn" style={{ display: "none" }}>
          {" "}
          <div className="text">View more</div>{" "}
          <svg
            className="frame"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 12H4"
              stroke="#00B031"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 17C15 17 20 13.3176 20 12C20 10.6824 15 7 15 7"
              stroke="#00B031"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      {isPanierDisplay && (
        <>
          <BagOrderAdmin
            handleValidateOrder={handleValidateOrder}
            handleCancelOrder={handleCancelOrder}
            currentOrder={selectedOrder}
            amountSelectedOrder={amountSelectedOrder}
            handleClick={closePanier}
          ></BagOrderAdmin>
        </>
      )}
    </div>
  );
};

export default LiveOrderSale;
