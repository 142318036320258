import React from "react";

function PanierItemAdmin(props) {
  return (
    <div itemID={props.id} className="item">
      <div className="item__struct">
        <div className="nbre">
          {props.item < 10 ? "0" + props.item : props.item}
        </div>
        <div className="item_name">{props.name}</div>
        <div className="nbre item_price">{Number(props.price)}</div>
      </div>

      {props.comment && props.comment !== "" ? (
        <div className="comment">
          <div className="comment__struct">{props.comment}</div>
        </div>
      ) : null}
    </div>
  );
}

export default PanierItemAdmin;
